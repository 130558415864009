import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { UserContext } from 'context/user/user';
import PreloaderTable from 'lib/components/Preloaders/Table';
import EmptyState from 'modules/components/EmptyState/EmptyState';
import httpService from 'service/httpService';
import userService from 'service/userService';

const Invitations = () => {
  const { invitations, setInvitations } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    getInvitations();
  }, [refresh, page]);

  return (
    <div>
      <div className="box2" style={{ padding: '20px' }}>
        {!isLoading && !invitations?.data?.length ? (
          <EmptyState
            title={'Manage your Invitations'}
            subtitle={`There are no invitations at this time!`}
          />
        ) : null}
        {isLoading && !invitations?.data?.length ? (
          <PreloaderTable />
        ) : invitations?.data?.length ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: '8px 16px' }}>
                    Organization
                  </TableCell>
                  <TableCell style={{ padding: '8px 16px' }}>Role</TableCell>
                  <TableCell style={{ padding: '8px 16px' }} align="right">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invitations?.data?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {item?.organizationMeta?.name}{' '}
                      <span
                        style={{
                          marginLeft: 12,
                          color:
                            item?.status === 'PENDING'
                              ? 'orange'
                              : item?.status === 'ACCEPTED'
                              ? '#00DC7D'
                              : '#FF0303',
                        }}>
                        {item?.status === 'PENDING'
                          ? '(Pending)'
                          : item?.status === 'ACCEPTED'
                          ? '(Accepted)'
                          : '(Rejected)'}
                      </span>
                    </TableCell>
                    <TableCell>{getRole(item?.roleName)}</TableCell>

                    <TableCell align="right">
                      {item?.status === 'PENDING' && (
                        <span className="d-flex gap-3 align-items-center justify-content-end">
                          <Button
                            variant="outlined"
                            onClick={() => rejectInvite(item?.id)}
                            className="rounded-pill text-danger px-4">
                            Reject
                          </Button>
                          <Button
                            onClick={() => acceptInvite(item?.id)}
                            className="rounded-pill bg-success text-white px-4">
                            Accept
                          </Button>
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </div>
      <div className="paginationWrapper">
        {invitations?.data?.length ? (
          <Pagination
            count={invitations?.totalPages}
            page={page}
            onChange={(e, value) => setPage(value)}
            variant="outlined"
            shape="rounded"
          />
        ) : null}
      </div>
    </div>
  );

  function getRole(role) {
    switch (role) {
      case 'SUPER_USER':
        return 'Super User';
      case 'SUB_ADMIN':
        return 'Sub Admin';
      case 'ACCOUNTANT':
        return 'Accountant';
      default:
    }
  }

  async function acceptInvite(id) {
    try {
      await userService.acceptInvite(id);
      setRefresh((prev) => !prev);
      httpService.toast.success('Invite accepted');
    } catch (ex) {
      httpService.errorHandler(ex);
    }
  }

  async function rejectInvite(id) {
    try {
      await userService.rejectInvite(id);
      setRefresh((prev) => !prev);
      httpService.toast.success('Invite rejected');
    } catch (ex) {
      httpService.errorHandler(ex);
    }
  }

  async function getInvitations() {
    try {
      setIsLoading(true);
      const { data } = await userService.getInvites('all', page);
      setInvitations(data);
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }
};

export default Invitations;
