import plansHash from 'lib/helpers/plansHash';
import * as constants from './constants';

const {
  fetchSingleOrg,
  fetchOrganizations,
  fetchInvitations,
  acceptInvitation,
  rejectInvitation,
  refreshOrganizations,
  refreshInvitations,
  fetchPlans,
  fetchCurrentPlan,
  fetchPlanReceipts,
  fetchEmployees,
  fetchSingleEmployee,
  fetchDepartments,
  fetchBranches,
} = constants;

const Reducer = (state, action) => {
  switch (action.type) {
    case 'cus_single':
      return {
        ...state,
        singleCustomer: action?.payload,
      };
    case 'invoice_single':
      return {
        ...state,
        singleInvoice: action?.payload,
      };
    case 'cnote_single':
      return {
        ...state,
        singleCreditNote: action?.payload,
      };
    case 'outflow_single':
      return {
        ...state,
        singleOutflow: action?.payload,
      };
    case 'vendor_single':
      return {
        ...state,
        singleVendor: action?.payload,
      };
    case fetchSingleOrg:
      const data = action.payload.data;
      localStorage.setItem('currency', data?.currency);
      localStorage.setItem('plan', plansHash[data?.subscriptionMeta?.planName]);
      return {
        ...state,
        user: { ...state.user, currentOrg: action?.payload },
      };
    case fetchOrganizations:
      return {
        ...state,
        user: { ...state.user, organizations: action?.payload },
      };
    case refreshOrganizations:
      return {
        ...state,
        user: { ...state.user, organizations: action?.payload },
      };
    // USER INVITATIONS
    case fetchInvitations:
      return {
        ...state,
        user: { ...state.user, invitations: action?.payload },
      };
    case refreshInvitations:
      return {
        ...state,
        user: { ...state.user, invitations: action?.payload },
      };
    case acceptInvitation:
      return {
        ...state,
        user: {
          ...state.user,
          invitations: state?.user?.invitations?.data?.filter(
            (item) => item?.id !== action?.payload?.id,
          ),
        },
      };
    case rejectInvitation:
      return {
        ...state,
        user: {
          ...state.user,
          invitations: state?.user?.invitations?.data?.filter(
            (item) => item?.id !== action?.payload?.id,
          ),
        },
      };
    // SUBSCRIPTIONS
    case fetchPlans:
      return {
        ...state,
        subscription: { ...state.subscription, plans: action?.payload },
      };
    case fetchCurrentPlan:
      return {
        ...state,
        subscription: { ...state.subscription, currentPlan: action?.payload },
      };
    case fetchPlanReceipts:
      return {
        ...state,
        subscription: { ...state.subscription, receipts: action?.payload },
      };
    // EMPLOYEE
    case fetchEmployees:
      return {
        ...state,
        employees: action?.payload,
      };
    case fetchSingleEmployee:
      return {
        ...state,
        singleEmployee: action?.payload,
      };
    // DEPARTMENT
    case fetchDepartments:
      return {
        ...state,
        departments: action?.payload,
      };
    // BRANCH
    case fetchBranches:
      return {
        ...state,
        branches: action?.payload,
      };
    default:
  }
};
export default Reducer;
