import React from 'react';

const InvoiceContext = React.createContext({});

const InvoiceContextProvider = ({ children }) => {
  const [invoices, setInvoices] = React.useState({});
  const [invoice, setInvoice] = React.useState(null);

  const [creditNotes, setCreditNotes] = React.useState({});
  const [creditNote, setCreditNote] = React.useState(null);

  return (
    <InvoiceContext.Provider
      value={{
        invoices,
        setInvoices,
        invoice,
        setInvoice,
        creditNotes,
        setCreditNotes,
        creditNote,
        setCreditNote,
      }}>
      {children}
    </InvoiceContext.Provider>
  );
};

export { InvoiceContext, InvoiceContextProvider };
