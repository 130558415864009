import http from './httpService';
import capitalize from 'lib/helpers/StringCapitalize';

const route = `/user/auth`;

export class Auth {
  login(data) {
    const formData = {
      email: data?.email,
      password: data?.password,
      platform: data?.platform,
    };
    return http.post(`${route}/auth-tokens`, formData);
  }
  register(data) {
    const formData = {
      email: data?.email,
      password: data?.password,
      phone: data?.phone,
      firstName: `${capitalize(data?.firstName)}`,
      lastName: `${capitalize(data?.lastName)}`,
      platform: data?.platform,
    };
    if (data?.referrerCode) formData.referrerCode = data?.referrerCode;
    return http.post(`${route}/signup`, formData);
  }
  requestAuthOTP(data) {
    return http.post(`${route}/otp-request`, data);
  }
  verifyAuthOTP(data) {
    return http.put(`${route}/otp-verification`, data);
  }
  forgotPassword(data) {
    const formData = { email: data?.email };
    return http.post(`${route}/password-reset-request`, formData);
  }
  resetPassword(token, data) {
    const formData = { password: data?.password, token };
    return http.put(`${route}/password-reset`, formData);
  }
  generateToken(refreshToken) {
    const formData = { refreshToken };
    return http.post(`${route}/access-token`, formData);
  }
}

export default new Auth();
