import React from 'react';

const FixedAssetsContext = React.createContext({});

const FixedAssetsContextProvider = ({ children }) => {
  const [draft, setDraft] = React.useState({});
  const [registered, setRegistered] = React.useState({});
  const [soldAndDisposed, setSoldAndDisposed] = React.useState({});
  const [asset, setAsset] = React.useState(null);
  return (
    <FixedAssetsContext.Provider
      value={{
        draft,
        setDraft,
        asset,
        setAsset,
        registered,
        setRegistered,
        soldAndDisposed,
        setSoldAndDisposed,
      }}>
      {children}
    </FixedAssetsContext.Provider>
  );
};

export { FixedAssetsContext, FixedAssetsContextProvider };
