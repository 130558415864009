import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { LoginContextProvider } from 'context/authContext/AuthContext';
import { GlobalContextProvider } from 'context/global/Index';

import 'loaders.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'index.scss';
import 'index.css';

import AppContextLayout from 'context';

const theme = createTheme({
  palette: {
    primary: { main: '#1f53d7', dark: '#1f53d7' },
    secondary: { main: '#EB5757' },
    error: { main: '#EB5757' },
    dark: { main: '#000' },
  },
  typography: {
    h1: {
      fontSize: '1.5rem',
      fontWeight: 'normal',
      margin: 0,
    },
    h2: {
      fontSize: '1.4rem',
      fontWeight: 'normal',
      margin: 0,
    },
    h3: {
      fontSize: '1.3rem',
      fontWeight: 'normal',
      margin: 0,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 'normal',
      margin: 0,
    },
    h5: {
      fontSize: '0.88rem',
      fontWeight: 'normal',
      margin: 0,
    },
    h6: {
      fontSize: '0.8rem',
      fontWeight: 'normal',
      margin: 0,
    },
    body1: {
      margin: 0,
      padding: 0,
      fontSize: '14px',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <LoginContextProvider>
      <GlobalContextProvider>
        <AppContextLayout>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AppContextLayout>
      </GlobalContextProvider>
    </LoginContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
