import Config from 'config/Config';

const { default: httpService } = require('./httpService');

class PublicService {
  constructor() {
    this.baseURL = Config.baseUrl;
    this.endpoints = {
      banks: this.baseURL.concat('/public/banks'),
      bankDetails: this.baseURL.concat('/public/bank-account-details'),
      countries: this.baseURL.concat('/public/countries'),
      states: this.baseURL.concat('/public/states-by-country'),
      cities: this.baseURL.concat('/public/cities-by-state'),
      plans: this.baseURL.concat('/public/plans'),
    };
  }
  async getBanks() {
    return await httpService.get(this.endpoints.banks);
  }
  async getPlans() {
    return await httpService.get(this.endpoints.plans);
  }
  async getBankAccountDetails(data) {
    const params = {};
    params.bank = data?.bankName;
    params.number = data?.accountNumber;
    return await httpService.get(this.endpoints.bankDetails, { params });
  }
  async getCountries(query) {
    const params = {};
    return await httpService.get(this.endpoints.countries, { params });
  }
  async getStates(query) {
    const params = { ...query };
    return await httpService.get(this.endpoints.states, { params });
  }
  async getCities(query) {
    const params = { ...query };
    return await httpService.get(this.endpoints.cities, { params });
  }
}

export default new PublicService();
