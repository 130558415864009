import httpService from './httpService';
import { User } from './userService';

const user = new User();
const OrgID = user.getOrgID();

const url = `/user/cp/org/${OrgID}/currencyrates`;
class Currency {
  async get() {
    return await httpService.get(url);
  }
  async all() {
    return await httpService.get(`${url}/get-available`);
  }
  async getCurrencies() {
    return await httpService.get('/user/cp/account/available-currencies');
  }
  async addOrUpdate(data, id) {
    const formData = {};
    formData.currencyName = data?.currencyName;
    formData.currencyValue = data?.currencyValue;
    return id
      ? await httpService.put(url, formData, { params: { id } })
      : await httpService.post(url, formData);
  }
  async delete(id) {
    const params = { id };
    return await httpService.delete(url, { params });
  }
}

export default new Currency();
