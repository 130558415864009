import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';

const ViewCommissions = ({ plans = [], isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{ className: 'w-100' }}>
      <div className="d-flex justify-content-between align-items-center gap-4 p-3 py-2 pe-2">
        <div>
          <div className="fs-5 fw-medium">Commissions</div>
          <div className="text-secondary fs-xs">
            Here is how much you will earn when users subscribe to a plan for
            the first time.
          </div>
        </div>
        <div>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </div>
      <Divider className="bg-secondary" />
      <DialogContent className="py-3">
        {plans?.map((item) => {
          const type = item?.commission?.new?.type;
          const amount = item?.commission?.new?.amount;
          return (
            <div
              key={item?.id}
              className="d-flex gap-4 align-items-center bg-light mb-4 rounded border p-3">
              <div className="flex-grow-1">{item?.name}</div>
              <div className="fw-medium">{amountTypeFormat(amount, type)}</div>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} type="button" variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function amountTypeFormat(amount, type) {
  if (type === 'PERCENTAGE') return `${amount}%`;
  return moneyFormat(amount, 'NGN');
}

export default ViewCommissions;
