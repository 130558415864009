import React from 'react';

const ProdAndSerContext = React.createContext({});

const ProdAndSerContextProvider = ({ children }) => {
  const [products, setProducts] = React.useState(null);
  const [product, setProduct] = React.useState(null);
  const [services, setServices] = React.useState(null);
  const [service, setService] = React.useState(null);
  const [stats, setStats] = React.useState({});
  const [topProducts, setTopProducts] = React.useState([]);

  return (
    <ProdAndSerContext.Provider
      value={{
        products,
        setProducts,
        product,
        setProduct,
        services,
        setServices,
        service,
        setService,
        stats,
        setStats,
        topProducts,
        setTopProducts,
      }}>
      {children}
    </ProdAndSerContext.Provider>
  );
};

export { ProdAndSerContext, ProdAndSerContextProvider };
