import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

const ReactSelect = ({ classes, value, handleChange, options, ...props }) => {
  const defaultValue = options?.find((val) => val.value === String(value));
  return (
    <Select
      className={classes}
      value={defaultValue}
      onChange={handleChange}
      options={options}
      {...props}
    />
  );
};

export const CreatableSelect = ({
  classes,
  value,
  handleChange,
  options,
  ...props
}) => {
  const defaultValue = options?.find((val) => val.value === String(value));
  return (
    <Creatable
      className={classes}
      value={defaultValue}
      onChange={handleChange}
      options={options}
      {...props}
    />
  );
};

export default ReactSelect;
