import React from 'react';

const SVGComponent = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M13.4062 14.1094H9.1875C8.79919 14.1094 8.48438 14.4242 8.48438 14.8125C8.48438 15.2008 8.79919 15.5156 9.1875 15.5156H13.4062C13.7946 15.5156 14.1094 15.2008 14.1094 14.8125C14.1094 14.4242 13.7946 14.1094 13.4062 14.1094Z"
        fill="currentColor"
      />
      <path
        d="M13.4062 16.9219H9.1875C8.79919 16.9219 8.48438 17.2367 8.48438 17.625C8.48438 18.0133 8.79919 18.3281 9.1875 18.3281H13.4062C13.7946 18.3281 14.1094 18.0133 14.1094 17.625C14.1094 17.2367 13.7946 16.9219 13.4062 16.9219Z"
        fill="currentColor"
      />
      <path
        d="M6.375 14.1094H4.96875C4.58044 14.1094 4.26562 14.4242 4.26562 14.8125C4.26562 15.2008 4.58044 15.5156 4.96875 15.5156H6.375C6.76331 15.5156 7.07812 15.2008 7.07812 14.8125C7.07812 14.4242 6.76331 14.1094 6.375 14.1094Z"
        fill="currentColor"
      />
      <path
        d="M6.375 16.9219H4.96875C4.58044 16.9219 4.26562 17.2367 4.26562 17.625C4.26562 18.0133 4.58044 18.3281 4.96875 18.3281H6.375C6.76331 18.3281 7.07812 18.0133 7.07812 17.625C7.07812 17.2367 6.76331 16.9219 6.375 16.9219Z"
        fill="currentColor"
      />
      <path
        d="M17.625 0H3.5625C2.39939 0 1.45312 0.946266 1.45312 2.10938V21.8906C1.45312 22.0771 1.52719 22.256 1.65909 22.3878L3.06534 23.7941C3.33989 24.0687 3.78511 24.0687 4.0597 23.7941L4.96875 22.885L5.87784 23.7941C6.15239 24.0687 6.59761 24.0687 6.8722 23.7941L7.78125 22.885L8.69034 23.7941C8.96489 24.0687 9.41011 24.0687 9.6847 23.7941L10.5938 22.885L11.5028 23.7941C11.7774 24.0687 12.2226 24.0687 12.4972 23.7941L13.4062 22.885L14.3153 23.7941C14.4526 23.9314 14.6325 24 14.8125 24C14.9925 24 15.1724 23.9314 15.3097 23.7941L16.7159 22.3878C16.8478 22.256 16.9219 22.0771 16.9219 21.8906V12.7031H20.4375H20.4376C21.6007 12.7031 22.5469 11.7569 22.5469 10.5938V4.96875C22.5469 4.78884 22.4748 4.60978 22.349 4.4798L20.9428 3.02667C20.8103 2.8898 20.628 2.8125 20.4375 2.8125C20.247 2.8125 20.0647 2.8898 19.9322 3.02667L19.7344 3.23109V2.10938C19.7344 0.946266 18.7882 0 17.625 0V0ZM15.5156 21.5994L14.8125 22.3025L13.9034 21.3934C13.6289 21.1188 13.1836 21.1188 12.909 21.3934L12 22.3025L11.0909 21.3934C10.8164 21.1188 10.3711 21.1188 10.0965 21.3934L9.1875 22.3025L8.27841 21.3934C8.00386 21.1188 7.55864 21.1188 7.28405 21.3934L6.375 22.3025L5.46591 21.3934C5.19136 21.1188 4.74614 21.1188 4.47155 21.3934L3.5625 22.3025L2.85938 21.5994V2.10938C2.85938 1.72167 3.1748 1.40578 3.5625 1.40578H15.6366C15.5585 1.62586 15.5156 1.86291 15.5156 2.10938V21.5994ZM16.9219 2.10938C16.9219 1.72167 17.2373 1.40625 17.625 1.40625C18.0127 1.40625 18.3281 1.72167 18.3281 2.10938V10.5938C18.3281 10.8402 18.371 11.0768 18.4491 11.2969H16.9219V2.10938ZM20.4375 4.52672L21.1406 5.25328V10.5938C21.1406 10.9815 20.8252 11.2969 20.4375 11.2969C20.0498 11.2969 19.7344 10.9815 19.7344 10.5938V5.25328L20.4375 4.52672Z"
        fill="currentColor"
      />
      <path
        d="M6.02738 13V9.6655H5.24438V8.6665H6.02738V7.6945H5.24438V6.6955H6.02738V3.361H8.14688L9.19988 6.6955H10.4284V3.361H11.9269V6.6955H12.6964V7.6945H11.9269V8.6665H12.6964V9.6655H11.9269V13H9.79388L8.71388 9.6655H7.52588V13H6.02738ZM7.47188 6.6955H7.84988L7.45838 5.3995H7.40438L7.47188 6.6955ZM7.52588 8.6665H8.41688L8.11988 7.6945H7.49888L7.52588 8.6665ZM9.83438 8.6665H10.4554L10.4284 7.6945H9.52388L9.83438 8.6665ZM10.4689 10.894H10.5364L10.4824 9.6655H10.1179L10.4689 10.894Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default SVGComponent;
