import useUserData from 'hooks/useUserData';
import { useState } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import notificationService from 'service/notificationService';
import userService, { User } from 'service/userService';
import initialState from './initialState';
import Reducer from './Reducer';

export const GlobalContext = createContext(initialState);

export const GlobalContextProvider = ({ children }) => {
  const { user } = useUserData();
  const [taxRate, setTaxRate] = useState('');
  const organizationId = new User().getOrgID();
  const [state, dispatch] = useReducer(Reducer, initialState);
  const [notifications, setNotifications] = useState({ data: [] });

  const currentPath = location.pathname.split('/')[1];

  useEffect(() => {
    if (!organizationId) return;
    if (currentPath !== 'dashboard') return;
    getTaxRates();
    getNotifications();
  }, [organizationId]);

  async function getTaxRates() {
    try {
      const { data } = await userService.getTaxRates();
      const taxes = data?.data?.taxes;
      const VAT = taxes?.find((item) => item?.name === 'VAT');
      setTaxRate(parseFloat(VAT?.rate));
      localStorage.setItem('[vat]', VAT?.rate);
    } catch (ex) {}
  }

  async function getNotifications(page = 1) {
    try {
      const { data } = await notificationService.get(page);
      setNotifications((prev) => ({
        ...data?.data?.notifications,
        data: [...prev?.data, ...data?.data.notifications?.docs],
      }));
    } catch (ex) {}
  }

  return (
    <GlobalContext.Provider
      value={{
        taxRate,
        getNotifications,
        notifications,
        dispatch,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};
