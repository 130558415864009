import React from 'react';
import httpService from 'service/httpService';
import useInternetConnection from './useInternetConnection';
import { RequestContext } from 'context/request-context';

const useFetch = ({
  requestFn,
  onSuccess,
  onError,
  keys = [],
  isEnabled = true,
  qKey = '',
}) => {
  const { requestData, setRequestData } = React.useContext(RequestContext);
  const { isOnline } = useInternetConnection();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [refetch, setRefetch] = React.useState(false);

  React.useEffect(() => {
    if (isEnabled === false) return;
    fetchData();
  }, [...keys, qKey, isEnabled, isOnline, refetch]);

  async function fetchData(variables) {
    try {
      if (!qKey) throw new Error('Query key is not present');
      if (!requestFn) throw new Error('RequestFn is not present');
      setIsLoading(true);
      const result = await requestFn(variables);
      if (onSuccess) onSuccess(result?.data);
      setRequestData((prev) => ({ ...prev, [qKey]: result?.data }));
      setIsLoading(false);
    } catch (error) {
      httpService.errorHandler(error);
      setIsLoading(false);
      if (onError) onError(error);
      setError(error);
    }
  }
  return {
    data: requestData[qKey] || null,
    isLoading,
    error,
    refetch: fetchData,
  };
};

export default useFetch;
