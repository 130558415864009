import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  LinearProgress,
  Paper,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Menu from 'lib/components/Menu/Menu';
import BTNLoader from 'lib/components/Preloaders/Btn';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import authValidation from 'validations/auth/authValidation';
import { Link, useHistory } from 'react-router-dom';
import authService from 'service/authService';
import httpService from 'service/httpService';
import useUserData from 'hooks/useUserData';
import { ReactComponent as MailIcon } from 'lib/assets/icons/mailbox.svg';
import { PATHNAMES } from 'lib/constants/pathnames';
import Header from 'modules/user/components/header';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  linear: {
    height: '5px',
    borderRadius: '5px',
    backgroundColor: '#edeeef',
    marginBottom: '10px',
  },
  linearBar: {
    borderRadius: '5px',
    backgroundColor: '#1f53d7',
    color: '#1f53d7',
  },
}));

const initialValues = {
  otp: '',
};

const message = 'Verification code sent successfully...';

const newInvite = Cookies.get('new_invite');

const Verification = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUserData();
  const [isLoading, setIsLoading] = React.useState(false);

  const { handleSubmit, errors, control } = useForm({
    mode: 'all',
    resolver: yupResolver(authValidation.VERIFICATION),
    defaultValues: initialValues,
  });

  const searchParams = new URLSearchParams(location.search);
  const REDIRECT = searchParams.get('redirect');
  const REFERRER_CODE = searchParams.get('referrerCode');
  let link = PATHNAMES.FEATURE_SELECTION;
  if (REFERRER_CODE) link = link + `?referrerCode=${REFERRER_CODE}`;

  React.useEffect(() => {
    if (!user) return;
    if (user?.isVerified) {
      httpService.toast.info('Account already verified');
      httpService.toast.info('Redirecting...');
      setTimeout(() => history.goBack(), 1500);
    }
  }, [user]);

  return (
    <div className="container-xxl">
      <Header />
      <Paper
        className="p-3 py-5 border mx-auto my-4 rounded-4"
        style={{ maxWidth: 600 }}>
        <div className="mb-3 px-3">
          <div className="row">
            <div className="col-4 text-primary fs-xs">Create Account</div>
            <div className="col-4 fs-xs text-primary text-center">
              Verification
            </div>
            <div className="col-4 fs-xs text-end">Organization Information</div>
          </div>
          <LinearProgress
            classes={{
              root: classes.linear,
              bar: classes.linearBar,
            }}
            variant="determinate"
            value={33}
          />
        </div>
        <div className="d-flex justify-content-center mx-auto">
          <MailIcon />
        </div>
        <div className="mb-4">
          <div className="text-center fs-sm">
            Check your email <span className="fw-medium">({user?.email})</span>{' '}
            or phone for verification code
          </div>
        </div>

        <form
          className="mx-auto"
          onSubmit={handleSubmit(save)}
          style={{ maxWidth: 450 }}>
          <div className="mb-5">
            <label>
              Verification Code: <span className="text-danger">*</span>
            </label>
            <Controller
              name="otp"
              control={control}
              render={(props) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  type={'text'}
                  disabled={user?.isVerified}
                  inputProps={{ maxLength: 5 }}
                  placeholder="Enter verification code..."
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['otp']?.message}
                  error={!!errors['otp']}
                />
              )}
            />
          </div>
          <div className="text-center fs-sm d-flex align-items-center justify-content-center my-2 mb-4">
            Did not receive an OTP?{' '}
            <Button
              disabled={user?.isVerified}
              variant="text"
              color="primary"
              onClick={requestAuthOTP}>
              Request again
            </Button>
          </div>
          <div className="mb-3">
            <Button
              fullWidth
              size="medium"
              variant="contained"
              color="primary"
              type="submit"
              style={{ textTransform: 'none' }}
              disabled={isLoading || user?.isVerified}
              startIcon={isLoading && <BTNLoader />}>
              Proceed
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );

  async function requestAuthOTP() {
    try {
      if (!user) return;
      if (user?.isVerified) return;
      await authService.requestAuthOTP({ email: user?.email });
      httpService.toast.success(message);
    } catch (ex) {
      httpService.errorHandler(ex);
    }
  }

  async function save(formData) {
    try {
      setIsLoading(true);
      const data = { code: formData.otp, email: user?.email };
      await authService.verifyAuthOTP(data);
      httpService.toast.success('Account verification successful...');
      handleRedirect();
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }

  function handleRedirect() {
    if (REDIRECT) window.location.replace(REDIRECT);
    else if (newInvite === 'true') {
      Cookies.remove('new_invite');
      window.location.replace(PATHNAMES.ORGANIZATIONS);
    } else window.location.replace(link);
  }
};

export default Verification;
