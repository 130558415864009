export default function getDeviceDetails() {
  const deviceInfo = {
    'os.name': '',
    'os.version': '',
    'browser.name': '',
    'browser.version': '',
    'navigator.userAgent': '',
    'navigator.appVersion': '',
    'navigator.platform': '',
    'navigator.vendor': '',
  };

  // Operating System details
  const os = window.navigator.appVersion;
  deviceInfo['os.version'] = os.match(/\b\d+\.\d+\.\d+\b/);
  deviceInfo['os.name'] = os.includes('Win')
    ? 'Windows'
    : os.includes('Mac')
    ? 'MacOS'
    : os.includes('X11')
    ? 'UNIX'
    : os.includes('Linux')
    ? 'Linux'
    : 'Unknown';

  // Browser details
  const browser = window.navigator.userAgent;
  deviceInfo['navigator.userAgent'] = browser;
  deviceInfo['browser.version'] = browser.match(
    /(Chrome|Firefox|Edge|Safari|Opera)\/(\S+)/,
  )[2];
  deviceInfo['browser.name'] = browser.includes('Chrome')
    ? 'Chrome'
    : browser.includes('Firefox')
    ? 'Firefox'
    : browser.includes('Edg')
    ? 'Edge'
    : browser.includes('Safari')
    ? 'Safari'
    : browser.includes('Opera')
    ? 'Opera'
    : 'Unknown';

  // Other details
  deviceInfo['navigator.appVersion'] = window.navigator.appVersion;
  deviceInfo['navigator.platform'] = window.navigator.platform;
  deviceInfo['navigator.vendor'] = window.navigator.vendor;

  return deviceInfo;
}
