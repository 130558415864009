import React from 'react';
import { Button, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { AppContext } from 'context/app';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { moneyFormat } from 'lib/helpers/formatCurrency';
import subscription from 'service/subscription';
import BTNLoader from 'lib/components/Preloaders/Btn';
import Header from 'modules/user/components/header';
import userService from 'service/userService';
import useFetch from 'hooks/useFetch';
import usePost from 'hooks/usePost';
import { StarOutline } from '@material-ui/icons';
import useUserData from 'hooks/useUserData';

const Preloader = () => {
  return (
    <div className="row">
      {[1, 2, 3].map((item) => (
        <div
          className="col-12 col-sm-6 col-md-4"
          key={item}
          style={{ height: 300 }}>
          <Paper
            style={{ padding: 16, border: '1px solid #ccc', borderRadius: 10 }}>
            <Skeleton variant="text" style={{ marginBottom: 20 }} />
            <Skeleton variant="rect" height={50} style={{ marginBottom: 20 }} />
            <Skeleton variant="h1" height={20} style={{ marginBottom: 20 }} />
            <Skeleton variant="text" style={{ marginBottom: 20 }} />
            <Skeleton variant="text" style={{ marginBottom: 20 }} />
            <Skeleton variant="text" style={{ marginBottom: 20 }} />
            <Skeleton variant="text" style={{ marginBottom: 20 }} />
            <Skeleton variant="text" style={{ marginBottom: 20 }} />
            <Skeleton variant="text" style={{ marginBottom: 20 }} />
            <Skeleton variant="text" style={{ marginBottom: 20 }} />
          </Paper>
        </div>
      ))}
    </div>
  );
};

const INTERVALS = [
  { title: 'Monthly', name: 'MONTHLY' },
  { title: 'Quarterly', name: 'QUARTERLY' },
  { title: 'Bi-annually', name: 'BIANNUALLY' },
  { title: 'Annually', name: 'ANNUALLY' },
];

const SubscriptionPlans = () => {
  const { user } = useUserData();
  const { plans, isFetching } = React.useContext(AppContext);
  const [subType, setSubType] = React.useState('');
  const [selected, setSelected] = React.useState('');
  const [billingInterval, setBillingInterval] = React.useState('MONTHLY');
  const searchParams = new URLSearchParams(window.location.search);

  const discountQuery = useFetch({
    qKey: 'referrer-discount',
    requestFn: () => userService.getDiscount(),
  });
  const subscriptionMutation = usePost({
    requestFn: (data) => subscription.subscriptionPayment(data),
    onSuccess: (data) => window.location.assign(data?.data?.url),
  });
  const freeTrialMutation = usePost({
    requestFn: (id) => subscription.freeTrial(id),
    onSuccess: (data) => window.location.assign(data?.data?.url),
  });

  const discount = discountQuery?.data?.data?.find((item) => {
    return (
      item?.status === 'PENDING' &&
      item?.type === 'SPONSORED_SIGNUP' &&
      Number(item?.amount) > 0
    );
  });

  const isDiscounted = !!discount;
  return (
    <div className="container-xxl">
      <Header />
      <div className="my-4">
        <div className="text-center fs-4 fw-medium"> Choose a plan</div>
        <div
          className={isDiscounted ? 'd-none' : 'text-center fs-4'}
          style={{ color: '#940CFE' }}>
          Promo prices last for the next 3 months
        </div>
      </div>
      <div
        className="rounded-pill border p-2 mx-auto mb-4"
        style={{ maxWidth: 700 }}>
        <div className="d-flex justify-content-between align-items-center overflow-auto gap-5">
          {INTERVALS.map((item) => (
            <div key={item?.name} className="">
              {item?.name === billingInterval ? (
                <Button
                  className="rounded-pill text-nowrap"
                  variant="contained"
                  color="primary">
                  {item?.title}{' '}
                  {item?.name === 'ANNUALLY' && (
                    <span
                      className="p-1 px-2 fs-sm rounded-3 ms-3"
                      style={{
                        backgroundColor: '#FFDC9C',
                        color: '#F2994A',
                      }}>
                      Save 20%
                    </span>
                  )}
                </Button>
              ) : (
                <Button
                  className="rounded-pill text-nowrap"
                  variant="text"
                  onClick={() => _selectInterval(item?.name)}>
                  {item?.title}{' '}
                  {item?.name === 'ANNUALLY' && (
                    <span
                      className="p-1 px-2 fs-sm rounded-3 ms-3"
                      style={{
                        backgroundColor: '#FFDC9C',
                        color: '#F2994A',
                      }}>
                      Save 20%
                    </span>
                  )}
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
      {!discount ? null : (
        <div className="text-center fst-italic mb-3 text-danger d-flex align-items-center fs-5 justify-content-center">
          <StarOutline className="me-3" />
          {discount?.amountType === 'FIXED'
            ? moneyFormat(discount?.amount, 'NGN')
            : `${discount?.amount}%`}{' '}
          discount applied from referral
          <StarOutline className="ms-3" />
        </div>
      )}
      <div className="mx-auto mb-5" style={{ maxWidth: 1000 }}>
        {isFetching && !plans?.length ? (
          <Preloader />
        ) : (
          <div>
            <div className="row">
              {plans?.map((item, index) => (
                <div className="col-12 col-sm-6 col-md-4" key={item?.id}>
                  <div
                    className="rounded-2 border mb-4 overflow-hidden"
                    style={{ background: '#F6FCFF' }}>
                    <div
                      className="text-center text-uppercase mb-3 p-3 fw-medium"
                      style={{
                        background:
                          index === 0
                            ? '#ACCDFF'
                            : index === 1
                            ? '#FFE8AC'
                            : '#DE99FE',
                      }}>
                      {item?.name}
                    </div>
                    <div className="px-3 md-3">
                      <div className="text-secondary fs-sm text-center text-decoration-line-through">
                        {discount?.amount > 0 ? (
                          <span>
                            {billingInterval === 'MONTHLY'
                              ? moneyFormat(
                                  item?.pricing?.monthly?.amount,
                                  'NGN',
                                )
                              : billingInterval === 'QUARTERLY'
                              ? moneyFormat(
                                  item?.pricing?.quarterly?.amount,
                                  'NGN',
                                )
                              : billingInterval === 'BIANNUALLY'
                              ? moneyFormat(
                                  item?.pricing?.biannually?.amount,
                                  'NGN',
                                )
                              : moneyFormat(
                                  item?.pricing?.annually?.amount,
                                  'NGN',
                                )}
                          </span>
                        ) : (
                          moneyFormat(item?.prevPrice[billingInterval], 'NGN')
                        )}
                      </div>
                      <div className="text-center fs-2 text-primary mb-2 fw-medium">
                        {billingInterval === 'MONTHLY'
                          ? moneyFormat(
                              getDiscountedAmount(
                                item?.pricing?.monthly?.amount,
                              ),
                              'NGN',
                            )
                          : billingInterval === 'QUARTERLY'
                          ? moneyFormat(
                              getDiscountedAmount(
                                item?.pricing?.quarterly?.amount,
                              ),
                              'NGN',
                            )
                          : billingInterval === 'BIANNUALLY'
                          ? moneyFormat(
                              getDiscountedAmount(
                                item?.pricing?.biannually?.amount,
                              ),
                              'NGN',
                            )
                          : moneyFormat(
                              getDiscountedAmount(
                                item?.pricing?.annually?.amount,
                              ),
                              'NGN',
                            )}
                      </div>
                      <div className="text-center fs-sm mb-3">
                        {item?.heading}
                      </div>
                    </div>
                    <ul className="list-unstyled p-3 mb-4">
                      {item?.content?.map((content) => (
                        <li key={content} className="d-flex gap-3 mb-2">
                          <CheckCircleIcon
                            style={{ fontSize: 16 }}
                            color="primary"
                          />{' '}
                          <span className="fs-sm">{content}</span>
                        </li>
                      ))}
                    </ul>
                    <div className="px-3 pb-3">
                      {isDiscounted ? null : user?.freeTrialUsageStatus !==
                        'PENDING' ? null : (
                        <Button
                          className="mb-3 bg-white"
                          color="primary"
                          fullWidth
                          variant="outlined"
                          disabled={freeTrialMutation.isLoading}
                          startIcon={
                            freeTrialMutation.isLoading &&
                            item?.id === selected &&
                            subType === 'FREE' && <BTNLoader />
                          }
                          onClick={() =>
                            _selectSubscription(
                              item?.id,
                              'FREE',
                              billingInterval,
                            )
                          }>
                          Free Trial
                        </Button>
                      )}

                      <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        disabled={subscriptionMutation.isLoading}
                        startIcon={
                          subscriptionMutation.isLoading &&
                          item?.id === selected &&
                          subType === 'PAID' && <BTNLoader />
                        }
                        onClick={() =>
                          _selectSubscription(item?.id, 'PAID', billingInterval)
                        }>
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  function _selectInterval(interval) {
    setBillingInterval(interval);
  }

  function _selectSubscription(id, type, interval) {
    setSubType(type);
    setSelected(id);
    if (type === 'PAID') subscribe(id, interval);
    if (type === 'FREE') freeTrial(id);
  }

  async function subscribe(planId, billingInterval) {
    if (subscriptionMutation.isLoading) return;
    const payload = { planId, billingInterval };
    if (isDiscounted) payload.discountId = discount?.id;
    subscriptionMutation.mutate(payload);
  }

  async function freeTrial(id) {
    if (freeTrialMutation.isLoading) return;
    if (user?.isFreeTrialUsed) return;
    freeTrialMutation.mutate(id);
  }

  function getDiscountedAmount(subscriptionAmount) {
    if (!discount) return subscriptionAmount;
    const type = discount?.amountType;
    const amount = discount?.amount;
    const discountAmount =
      type === 'FIXED' ? amount : (amount / 100) * subscriptionAmount;
    return subscriptionAmount - discountAmount;
  }
};

export default SubscriptionPlans;
