import React from 'react';

const TransactionContext = React.createContext({});

const TransactionContextProvider = ({ children }) => {
  const [transactions, setTransactions] = React.useState({});
  const [reconciliation, setReconciliation] = React.useState(null);
  const [accounts, setAccounts] = React.useState([]);
  const [banks, setBanks] = React.useState([]);
  return (
    <TransactionContext.Provider
      value={{
        banks,
        setBanks,
        accounts,
        setAccounts,
        transactions,
        setTransactions,
        reconciliation,
        setReconciliation,
      }}>
      {children}
    </TransactionContext.Provider>
  );
};

export { TransactionContext, TransactionContextProvider };
