import httpService from './httpService';

class ReferrerService {
  constructor() {
    this.endpoints = Object.freeze({
      profile: `/user/cp/referral/me`,
      bank: `/user/cp/referral/me/bank`,
      referrals: `/user/cp/referral/me/referrals`,
      referral: `/user/cp/referral/me/referrals/single`,
      earnings: `/user/cp/referral/me/earnings`,
      earning: `/user/cp/referral/me/earnings/referral`,
    });
  }
  async profile() {
    return await httpService.get(this.endpoints.profile);
  }
  async update(data) {
    return await httpService.put(this.endpoints.profile, data);
  }
  async updateBank(data) {
    const formData = {
      name: data?.bankName,
      accountName: data?.accountName,
      accountNumber: String(data?.accountNumber),
    };
    return await httpService.put(this.endpoints.bank, formData);
  }
  async referrals(query = {}) {
    const params = {};
    if (query?.page) params.page = query.page;
    if (query?.plan) params.plan = query.plan;
    if (query?.limit) params.limit = query.limit;
    if (query?.status) params.status = query.status;
    if (query?.keyword) params.keyword = query.keyword;
    if (query?.referrerId) params.referrerId = query.referrerId;
    if (query.plan === 'ALL') delete params.plan;
    if (query.status === 'ALL') delete params.status;
    return await httpService.get(this.endpoints.referrals, { params });
  }
  async getReferral(query = {}) {
    const params = {};
    if (query?.id) params.id = query.id;
    return await httpService.get(this.endpoints.referral, { params });
  }
  async getEarnings(query = {}) {
    const params = {};
    if (query?.page) params.page = query.page;
    if (query?.plan) params.plan = query.plan;
    if (query?.limit) params.limit = query.limit;
    if (query?.status) params.status = query.status;
    if (query?.referrerId) params.referrerId = query.referrerId;
    if (query.plan === 'ALL') delete params.plan;
    if (query.status === 'ALL') delete params.status;
    return await httpService.get(this.endpoints.earnings, { params });
  }
  async referralEarnings(query = {}) {
    const params = {};
    if (query?.id) params.id = query.id;
    if (query?.page) params.page = query.page;
    if (query?.plan) params.plan = query.plan;
    if (query?.limit) params.limit = query.limit;
    if (query?.status) params.status = query.status;
    if (query.plan === 'ALL') delete params.plan;
    if (query.status === 'ALL') delete params.status;
    return await httpService.get(this.endpoints.earning, { params });
  }
}

export default new ReferrerService();
