import Home from 'modules/Home/index';
import SiteLayout from 'modules/SiteLayout/SiteLayout';
import UserResponse from 'modules/HumanResources/PerformanceManagement/response';
import PayNow from 'modules/Invoice/view/PayNow';

import ForgotPassword from 'modules/authentication/forgot-password';
import EmailSent from 'modules/authentication/forgot-password-success';
import ResetPassword from 'modules/authentication/reset-password';
import GenerateToken from 'modules/authentication/generateToken';
import Verification from 'modules/authentication/verification';
import SignUp from 'modules/authentication/signup';
import SignIn from 'modules/authentication/signin';
import SignOut from 'modules/authentication/signout';
import SubscriptionPlans from 'modules/Plans/subscriptionPlans';
import Organizations from 'modules/user/organizations';
import CreateOrganization from 'modules/user/organizations/create';
import AccountSettings from 'modules/user/settings';
import { PATHNAMES } from 'lib/constants/pathnames';
import Referrer from 'modules/user/referrer';
import FeatureSelection from 'modules/user/organizations/options';
import NewInvites from 'modules/invitations';

const routes = [
  {
    title: 'Home',
    path: '/',
    component: Home,
    exact: true,
  },
  // Account
  {
    title: 'Sign Up',
    path: PATHNAMES.SIGN_UP,
    component: SignUp,
    exact: true,
  },
  {
    title: 'Sign In',
    path: PATHNAMES.SIGN_IN,
    component: SignIn,
    exact: true,
  },
  {
    title: 'Sign Out',
    path: PATHNAMES.SIGN_OUT,
    component: SignOut,
    exact: true,
  },
  {
    title: 'Verification',
    path: PATHNAMES.ACCOUNT_VERIFICATION,
    component: Verification,
    exact: true,
    private: true,
  },

  {
    title: 'Forgot Password',
    path: PATHNAMES.FORGOT_PASSWORD,
    component: ForgotPassword,
    exact: true,
  },
  {
    title: 'Forgot Password Success',
    path: PATHNAMES.FORGOT_PASSWORD_SUCCESS,
    component: EmailSent,
    exact: true,
  },
  {
    title: 'Reset Password',
    path: PATHNAMES.RESET_PASSWORD,
    component: ResetPassword,
    exact: true,
  },
  {
    title: 'Invites',
    path: PATHNAMES.NEW_INVITES,
    component: NewInvites,
    exact: true,
  },
  {
    title: 'Account',
    path: PATHNAMES.ACCOUNT,
    component: AccountSettings,
    private: true,
  },
  {
    title: 'Organizations',
    path: `/organizations`,
    component: Organizations,
    exact: true,
    private: true,
  },
  {
    title: 'Create Organizations',
    path: `/organizations/new`,
    component: CreateOrganization,
    exact: true,
    private: true,
  },
  {
    title: 'Feature selection',
    path: PATHNAMES.FEATURE_SELECTION,
    component: FeatureSelection,
    exact: true,
    private: true,
  },
  {
    title: 'Referral',
    path: PATHNAMES.REFERRAL,
    component: Referrer,
    private: true,
  },
  {
    title: 'Plans',
    path: '/plans',
    component: SubscriptionPlans,
    exact: true,
    private: true,
  },

  {
    title: 'DashBoard',
    path: '/dashboard',
    component: SiteLayout,
    private: true,
  },
  {
    title: 'Performance Response',
    path: '/performance-response',
    component: UserResponse,
  },
  {
    title: 'Invoice Payment',
    path: '/invoice/:id',
    component: PayNow,
  },
];

export default routes;
