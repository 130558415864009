import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './routes';
import { PrivateRoute } from 'lib/components/PrivateRoute/PrivateRoute';
import Notfoundpage from 'modules/404';
import React from 'react';
import useInternetConnection from 'hooks/useInternetConnection';
import { Snackbar } from '@material-ui/core';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import { Alert } from '@material-ui/lab';
import ScrollToTop from 'lib/components/ScrollToTop';
import { ToastContainer } from 'react-toastify';

function App() {
  const { isOnline } = useInternetConnection();
  return (
    <React.Fragment>
      <ToastContainer />
      {isOnline ? null : (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={true}
          title="Internet connection failed. Please check your internet settings">
          <Alert className="bg-dark text-white" severity="info">
            <WifiOffIcon /> Internet connection failed. Please check your
            internet settings
          </Alert>
        </Snackbar>
      )}
      <Router>
        <Switch>
          {routes.map((route, i) =>
            route.private ? (
              <PrivateRoute key={i} component={route.component} {...route} />
            ) : (
              <Route key={i} {...route} />
            ),
          )}
          <Route path="*" component={Notfoundpage} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
