import * as Yup from 'yup';

const profileFormData = {
  schema: Yup.object({
    businessName: Yup.string().min(3).required().label('Business Name'),
    state: Yup.string().required().label('State'),
    lga: Yup.string().required().label('LGA'),
    number: Yup.string().required().label('House Number'),
    street: Yup.string().required().label('Street'),
    city: Yup.string().required().label('City'),
    businessStartDate: Yup.date(),
    country: Yup.string().required().label('Country'),
    phone: Yup.string().trim().required().label('Phone Number'),
    businessType: Yup.string().required().label('Business Type'),
    businessSector: Yup.string().label('Business Sector'),
  }),
};
const profileFormDataII = {
  schema: Yup.object({
    businessName: Yup.string().min(3).required().label('Business Name'),
    state: Yup.string().required().label('State'),
    lga: Yup.string().required().label('LGA'),
    number: Yup.string().required().label('House Number'),
    street: Yup.string().required().label('Street'),
    city: Yup.string().required().label('City'),
    businessStartDate: Yup.date(),
    country: Yup.string().required().label('Country'),
    phone: Yup.string().trim().required().label('Phone Number'),
    registrationNumber: Yup.string().required().label('Registeration Number'),
    dateOfIncorporation: Yup.date().required().label('Date of Incorporation'),
    businessType: Yup.string().required().label('Business Type'),
    businessSector: Yup.string().label('Business Sector'),
  }),
};

const completeProfileData = {
  schema: Yup.object({
    otp: Yup.string()
      .trim()
      .matches(/^[0-9]*$/, 'Invalid OTP format')
      .required('OTP is required for verification'),
  }),
};

const createAccount = {
  schema: Yup.object({
    firstName: Yup.string()
      .trim()
      .matches(/^[a-zA-z]*$/, 'Numbers are not allowed')
      .required('First name is required'),
    lastName: Yup.string()
      .trim()
      .matches(/^[a-zA-z]*$/, 'Digits not allowed')
      .required('Last name is required'),
    email: Yup.string()
      .trim()
      .email('Email address is invalid')
      .required('Email address is required'),
    phone: Yup.string().trim().required().label('Phone Number'),
    referralCode: Yup.string().label('Referral Code'),
    password: Yup.string()
      .trim()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .trim()
      .required()
      .label('Confirm Password')
      .oneOf([Yup.ref('password')], 'Passwords does not match'),
  }),
};

const signIn = {
  schema: Yup.object({
    email: Yup.string()
      .trim()
      .email('Email must be valid')
      .required('Email address is required'),
    password: Yup.string()
      .trim()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  }),
};
const findEmail = {
  schema: Yup.object({
    email: Yup.string()
      .trim()
      .email('Email must be valid')
      .required('Email address is required'),
  }),
};
const resetPassword = {
  schema: Yup.object({
    password: Yup.string()
      .trim()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .trim()
      .required()
      .label('Confirm Password')
      .oneOf([Yup.ref('password')], 'Passwords does not match'),
  }),
};

const addEmployee = {
  schema: Yup.object({
    image: Yup.string().test('type', 'We only support jpeg', (value) => {
      return !['image/jpeg', 'image/png', 'image/jpg'].includes(
        value && value[0].type,
      );
    }),
    gender: Yup.string().required('Gender is required'),
    phone: Yup.string().required('Phone number is required'),
    middleName: Yup.string('Middle name is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    department: Yup.string().required('Department is required'),
    designation: Yup.string().required('Designation is required'),
    employmentType: Yup.string().required('Employment type is required'),
    NIN: Yup.string(),
    TIN: Yup.string(),
    no: Yup.string().required('Required'),
    street: Yup.string().required('Field is required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('State is required'),
    lga: Yup.string().required('LGA is required'),
    email: Yup.string().email().required('Email is required'),
    employeeManager: Yup.string(),
    dateOfHire: Yup.date().required('Date of hire is required'),
    dateOfBirth: Yup.date().required('D.O.B is required'),
    workLocation: Yup.string().required('Work location is required'),
    vacationPolicy: Yup.string().required('Vacation policy is required'),
    // deductions: Yup.string().required(),
    bankName: Yup.string(),
    accountNo: Yup.string(),
    accountName: Yup.string(),
    // status: Yup.string().required(),
    inviteButton: Yup.boolean(),
    nextOfKin: Yup.string(),
    nextOfKinPhone: Yup.string(),
    nextOfKinRelationship: Yup.string(),
    annualSalary: Yup.number().typeError('Amount must be a number'),
  }),
};

const singleInventoryFrom = {
  schema: Yup.object({
    type: Yup.string(),
    name: Yup.string().required(),
    description: Yup.string(),
    vatExempted: Yup.string().required('Vat exempted is required'),
    sku: Yup.string(),
    code: Yup.string().required(),
    unit: Yup.string().required(),
    costPricing: Yup.object({
      price: Yup.number()
        .typeError('Price must be a number')
        .required('Price is required'),
      accountId: Yup.string(),
      vatRate: Yup.number().typeError('Amount must be a number'),
      vatInclusive: Yup.boolean().when('costPricing.vatInclusive', {
        is: (value) => value == undefined,
        then: Yup.boolean().default(true),
      }),
    }),
    sellingPricing: Yup.object({
      price: Yup.number('this must be a number')
        .typeError('Price must be a number')
        .required('Price is required'),
      accountId: Yup.string(),
      vatRate: Yup.number().typeError('Amount must be a number'),
      vatInclusive: Yup.boolean().default(true),
    }),
    totalStockQuantity: Yup.number()
      .typeError('Amount must be a number')

      .typeError('Amount must be a number')
      .required('Inventory at Hand is required'),
    asOfDate: Yup.date().required('As of Date is required'),
    accountId: Yup.string().required('Inventory Asset Account is required'),
    lowStockCount: Yup.number()
      .typeError('Amount must be a number')
      .required('Low stock alert is required'),
  }),
};

const variableInventoryFrom = {
  schema: Yup.object({
    type: Yup.string(),
    name: Yup.string().required(),
    description: Yup.string(),
    vatExempted: Yup.string().required('Vat exempted is required'),
    sku: Yup.string().required(),
    code: Yup.string().required(),
    unit: Yup.string().required(),
    accountId: Yup.string().required(),
    costPricing: Yup.object({
      price: Yup.number()
        .typeError('Amount must be a number')
        .required('Cost Price is required'),
      accountId: Yup.string().required('Account id  is required'),
      vatInclusive: Yup.boolean().default(false),
    }),
    sellingPricing: Yup.object({
      price: Yup.number()
        .typeError('Amount must be a number')
        .required('Selling price is required'),
      accountId: Yup.string().required('Account id  is required'),
      vatInclusive: Yup.boolean().default(false),
    }),
    totalStockQuantity: Yup.number()
      .typeError('Amount must be a number')
      .required('Inventory at Hand is required'),
    lowStockCount: Yup.number()
      .typeError('Amount must be a number')
      .required('Low stock alert is required'),
    variant: Yup.object({
      name: Yup.string().required('Name is required'),
      price: Yup.number()
        .typeError('Amount must be a number')
        .required('Price  is required'),
      code: Yup.string().required('Code is required'),
      unit: Yup.string().required(),
      unitCount: Yup.number()
        .typeError('Amount must be a number')
        .required('UnitCount is required'),
    }),
  }),
};
const serviceInventoryFrom = {
  schema: Yup.object({
    type: Yup.string(),
    name: Yup.string().required(),
    description: Yup.string(),
    vatExempted: Yup.string(),
    price: Yup.number()
      .typeError('Amount must be a number')
      .required('Cost price is required'),
    accountId: Yup.string(),
    vatInclusive: Yup.boolean(),
  }),
};

const adjustQuanityForm = {
  schema: Yup.object({
    productId: Yup.string(),
    stockId: Yup.string(),
    quantity: Yup.number().typeError('Amount must be a number').required(),
    reason: Yup.string().required(),
  }),
};
const PersonalProfileSchema = {
  schema: Yup.object({
    firstName: Yup.string().required().label('First Name'),
    lastName: Yup.string().required().label('Last Name'),
    email: Yup.string(),
    phone: Yup.string().required().label('Phone Number'),
    gender: Yup.string(),
  }),
};
const PersonalAccountSchema = {
  schema: Yup.object({
    bankName: Yup.string().required().label('Bank Name'),
    accountName: Yup.string().required().label('Account Name'),
    accountNumber: Yup.string()
      .matches(/^[0-9]*$/)
      .required()
      .label('Account Number'),
  }),
};
const UpdatePasswordSchema = {
  schema: Yup.object({
    password: Yup.string()
      .trim()
      .min(6, 'Password must be at least 6 characters')
      .required()
      .label('Password'),
    newPassword: Yup.string()
      .trim()
      .min(6, 'New Password must be at least 6 characters')
      .required()
      .label('New Password'),
    confirmNewPassword: Yup.string()
      .trim()
      .required()
      .oneOf([Yup.ref('newPassword')], 'Passwords does not match')
      .label('Confirm New Password'),
  }),
};

export {
  profileFormData,
  PersonalAccountSchema,
  completeProfileData,
  createAccount,
  signIn,
  addEmployee,
  findEmail,
  singleInventoryFrom,
  variableInventoryFrom,
  serviceInventoryFrom,
  adjustQuanityForm,
  resetPassword,
  UpdatePasswordSchema,
  PersonalProfileSchema,
  profileFormDataII,
};
