import { yupResolver } from '@hookform/resolvers/yup';
import { UserContext } from 'context/user/user';
import BTNLoader from 'lib/components/Preloaders/Btn';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import httpService from 'service/httpService';
import userService from 'service/userService';
import { PersonalProfileSchema } from 'site-constants';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

const Profile = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { user, setRefetch } = React.useContext(UserContext);
  const { handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(PersonalProfileSchema.schema),
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: '',
    },
  });

  React.useEffect(() => {
    reset(user);
  }, [user]);
  return (
    <div>
      <div className="box2" style={{ padding: '20px' }}>
        <p style={{ fontWeight: 'bold', fontSize: 20 }}>Personal information</p>
        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid item xs={12} sm={6} md={6}>
            <form onSubmit={handleSubmit(save)}>
              <div className="space-bottom">
                <Grid container spacing={2} className="space-bottom">
                  <Grid item xs={12} sm={6} md={6} className="mb-3">
                    <div>
                      <label htmlFor="First Name" className="mb-1">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="firstName"
                        control={control}
                        render={(props) => (
                          <TextField
                            fullWidth
                            {...props}
                            type="text"
                            size="small"
                            variant="outlined"
                            placeholder="First Name"
                            inputRef={props?.ref}
                            error={!!errors['firstName']}
                            helperText={errors['firstName']?.message}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="mb-3">
                    <div>
                      <label htmlFor="Last Name" className="mb-1">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="lastName"
                        control={control}
                        render={(props) => (
                          <TextField
                            fullWidth
                            {...props}
                            type="text"
                            size="small"
                            variant="outlined"
                            placeholder="Last Name"
                            inputRef={props?.ref}
                            error={!!errors['lastName']}
                            helperText={errors['lastName']?.message}
                          />
                        )}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="mb-3">
                  <label className="mb-1">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    render={(props) => (
                      <TextField
                        fullWidth
                        {...props}
                        type="email"
                        size="small"
                        disabled
                        variant="outlined"
                        style={{ background: '#f3f3f6' }}
                        placeholder="Email"
                        inputRef={props?.ref}
                        error={!!errors['email']}
                        helperText={errors['email']?.message}
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label className="mb-1">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="phone"
                    control={control}
                    render={(props) => (
                      <PhoneInput
                        {...props}
                        country={'ng'}
                        value={`${props.value}`}
                        onChange={(v) => props.onChange(v)}
                        inputStyle={{
                          height: '38px',
                          width: '100%',
                        }}
                      />
                    )}
                  />
                  {!!errors['phone'] && (
                    <span
                      className="text-danger"
                      style={{ fontSize: 12, marginLeft: 24 }}>
                      {errors['phone']?.message}
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="mb-1">Gender</label>
                  <Controller
                    name="gender"
                    control={control}
                    render={(props) => (
                      <FormControl
                        error={!!errors['gender']}
                        size="small"
                        fullWidth>
                        <Select
                          {...props}
                          fullWidth
                          variant="outlined"
                          inputRef={props?.ref}>
                          <MenuItem value="" disabled className="px-3">
                            Select Gender
                          </MenuItem>
                          <MenuItem value="male" className="px-3">
                            Male
                          </MenuItem>
                          <MenuItem value="female" className="px-3">
                            Female
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          {errors['gender']?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
              </div>

              <Button
                disabled={isLoading}
                startIcon={isLoading && <BTNLoader />}
                color="primary"
                variant="contained"
                type="submit">
                Save
              </Button>
              <Button
                component={Link}
                to="/dashboard/settings"
                className="space-left2"
                variant="outlined">
                Cancel
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  async function save(data) {
    try {
      setIsLoading(true);
      await userService.updateCurrentUser(data);
      setRefetch((prev) => !prev);
      setIsLoading(false);
      httpService.toast.success('Profile information updated.');
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }
};

export default Profile;
