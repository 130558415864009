import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
} from '@material-ui/core';
import BrandLogo from 'lib/assets/icons/brand_logo.png';
import React from 'react';
import { useHistory } from 'react-router-dom';
import httpService from 'service/httpService';
import performanceService from 'service/performanceService';
import BTNLoader from 'lib/components/Preloaders/Btn';
import { MessageFeedback } from 'modules/components/Feedback/Feedback';
import moment from 'moment';
import { InfoOutlined } from '@material-ui/icons';
import { FullLoader } from 'lib/components/Loaders/LoadingIndicator';
import CheckImg from '../../../lib/assets/images/success_check.png';
import ResponseImg from '../../../lib/assets/images/response_feedback.png';

const UserResponse = () => {
  const history = useHistory();
  const [survey, setSurvey] = React.useState(null);
  const [questions, setQuestions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [feedback, setFeedback] = React.useState({
    severity: '',
    message: '',
    open: false,
  });

  const queryParams = new URLSearchParams(location.search);
  const evaluationId = queryParams.get('publicId');
  const status = queryParams.get('status');

  React.useEffect(() => {
    window.scroll(0, 0);
  }, []);

  React.useEffect(() => {
    getSurvey();
  }, []);

  async function getSurvey() {
    try {
      setIsLoading(true);
      const { data } = await performanceService.getPublicSurvey(evaluationId);
      setSurvey(data?.data);
      populateQuestions(data?.data?.questions);
      setIsLoading(false);
    } catch (ex) {
      httpService.showFeedback(ex, setFeedback);
      setIsLoading(false);
      if (ex?.response?.data?.message) handleChangeQuery('status', 'error');
    }
  }

  async function save() {
    try {
      setIsSubmitting(true);
      await performanceService.submitResponse(evaluationId, questions);
      httpService.showSuccess('Response recorded', setFeedback);
      setIsSubmitting(false);
      handleChangeQuery('status', 'successful');
    } catch (ex) {
      httpService.showFeedback(ex, setFeedback);
      setIsSubmitting(false);
    }
  }

  const populateQuestions = (data) => {
    setQuestions(
      data?.map((item) => ({
        ...item,
        answer: '',
      })),
    );
  };

  const handleChangeQuery = (key, value) => {
    queryParams.set(key, value || '');
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
    if (key === 'page') window.scroll(0, 0);
  };

  const handleChangeAnswer = (value, id) => {
    setQuestions((prev) =>
      prev.map((q) => {
        if (q?.id === id) {
          return { ...q, answer: value };
        }
        return q;
      }),
    );
  };

  const employee = survey?.participant?.employeeMeta;

  if (isLoading && !survey) return <FullLoader />;
  if (status === 'error')
    return (
      <React.Fragment>
        <div
          style={{ minHeight: '100vh' }}
          className="d-flex flex-column my-5 align-items-center justify-content-center">
          <img style={{ maxWidth: 400 }} src={ResponseImg} alt="" />
          <p className="fs-4 my-3 text-center" style={{ maxWidth: 700 }}>
            This user has already completed the evaluation form; please wait for
            the final conclusion, which will be available soon.
          </p>
        </div>
      </React.Fragment>
    );
  if (status === 'successful')
    return (
      <React.Fragment>
        <div
          style={{ minHeight: '100vh' }}
          className="d-flex flex-column my-5 align-items-center justify-content-center">
          <img style={{ maxWidth: 300 }} src={CheckImg} alt="" />
          <p className="fs-4 my-3 text-center" style={{ maxWidth: 700 }}>
            Performance Evaluation has been successfully submitted; please await
            a response form the HR
          </p>
        </div>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <header className="d-flex p-4 justify-content-center align-items-center mb-4">
        <img
          src={BrandLogo}
          alt="brand"
          style={{ width: '7rem', height: '3rem' }}
        />
      </header>
      <div className="container-xl">
        <div className="mb-4">
          <div className="fs-3 fw-medium">Hello {employee?.firstName},</div>
          <div>
            Fill in the required information to the performance evaluation below
          </div>
        </div>
        <div>
          <div className="fs-6 fw-medium my-3 d-flex align-items-center gap-4">
            Part A {isLoading && !survey ? <BTNLoader /> : null}
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="">Employee Information</div>
            <div className="">
              Date: {moment(survey?.startAt).format('DD/MM/YYYY')}
            </div>
          </div>
          <div className="border p-3 rounded mb-4">
            <div className="row align-items-center mb-2">
              <div className="col-12 col-md-3">
                <label htmlFor="" className="d-block">
                  Employee Name:{' '}
                </label>
              </div>
              <div className="col-12 col-md-9">
                <div className="row">
                  <div className="col-12 col-md-4 mb-3">
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="First Name"
                      value={employee?.firstName}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="Last Name"
                      value={employee?.lastName}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="Other Name"
                      value={employee?.middleName}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center mb-2">
              <div className="col-12 col-md-3">
                <label htmlFor="" className="d-block">
                  Job Title:{' '}
                </label>
              </div>
              <div className="col-12 col-md-9">
                <div className="row">
                  <div className="col-12 col-md-4 mb-3">
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="First Name"
                      value={employee?.role}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center mb-2">
              <div className="col-12 col-md-3">
                <label htmlFor="" className="d-block">
                  Employee ID:{' '}
                </label>
              </div>
              <div className="col-12 col-md-9">
                <div className="row">
                  <div className="col-12 col-md-4 mb-3">
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="Employee ID"
                      value={employee?.companyId}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center mb-2">
              <div className="col-12 col-md-3">
                <label htmlFor="" className="d-block">
                  Department:{' '}
                </label>
              </div>
              <div className="col-12 col-md-9">
                <div className="row">
                  <div className="col-12 col-md-4 mb-3">
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="Department"
                      value={employee?.department}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center mb-2">
              <div className="col-12 col-md-3">
                <label htmlFor="" className="d-block">
                  Branch:{' '}
                </label>
              </div>
              <div className="col-12 col-md-9">
                <div className="row">
                  <div className="col-12 col-md-4 mb-3">
                    <TextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      type="text"
                      placeholder="Branch"
                      value={employee?.branch}
                      InputProps={{ readOnly: true }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="p-3 border d-flex gap-3 mb-4"
          style={{ background: '#F2F3FF' }}>
          <div>
            <InfoOutlined className="fs-3" style={{ fill: '#eed202' }} />
          </div>
          <div>
            <div className="mb-3 fs-5">
              This is the employee self-appraisal form.
            </div>
            <div>
              You are expected to answer all mandatory question in this section,
              all questions have points attached to them which will form the
              overall score to be assigned to you.
            </div>
          </div>
        </div>
        <React.Fragment>
          <div className="fs-6 fw-medium my-3">Part B</div>
          <div className="mb-3">
            <div className="">
              Performance Evaluation (to be filled by employee)
            </div>
            <div className="fw-medium fs-6 text-danger">Compulsory Fields*</div>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="border p-3 rounded mb-4">
              {questions?.map((item) => {
                const ans = item?.answer;
                return (
                  <div className="mb-4" key={item?.id}>
                    <label className="d-flex fw-medium mb-1">
                      {item?.title}
                      <span className="d-flex align-items-center gap-3">
                        {!item?.mandatory ? null : (
                          <span className="text-danger"> *</span>
                        )}
                        {item?.type === 'TEXT' ? null : `${item?.score} points`}
                      </span>
                    </label>
                    {item?.type !== 'TEXT' ? null : (
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={ans}
                        onChange={(e) =>
                          handleChangeAnswer(e.target.value, item?.id)
                        }
                      />
                    )}
                    {item?.type !== 'RADIO' ? null : (
                      <RadioGroup
                        row
                        onChange={(e) =>
                          handleChangeAnswer(e.target.value, item?.id)
                        }
                        value={ans}>
                        {item?.options?.map((item) => (
                          <FormControlLabel
                            key={item?.id}
                            value={item?.value}
                            control={<Radio size="" color="primary" />}
                            label={
                              <span style={{ fontSize: '16px' }}>
                                {item?.label}
                              </span>
                            }
                          />
                        ))}
                      </RadioGroup>
                    )}
                    {item?.type !== 'NUMBER_INPUT' ? null : (
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        type="number"
                        value={ans}
                        onChange={(e) =>
                          handleChangeAnswer(e.target.value, item?.id)
                        }
                      />
                    )}
                    {item?.type !== 'SLIDER' ? null : (
                      <div className="d-flex align-items-center gap-3">
                        <Slider
                          value={Number(ans)}
                          onChange={(_, v) => handleChangeAnswer(v, item?.id)}
                        />
                        <span>{ans}</span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-end my-3 mb-5">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                startIcon={isSubmitting ? <BTNLoader /> : null}
                onClick={save}>
                Submit
              </Button>
            </div>
          </form>
        </React.Fragment>
      </div>
      <MessageFeedback
        open={feedback?.open}
        severity={feedback?.severity}
        message={feedback?.message}
        handleClose={() => setFeedback({ ...feedback, open: false })}
      />
    </React.Fragment>
  );
};

export default UserResponse;
