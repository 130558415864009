import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';
import httpService from 'service/httpService';
import referrerService from 'service/referrerService';

const ReferrerContext = React.createContext({});

const ReferrerContextProvider = ({ children }) => {
  const pathname = location.pathname;

  return (
    <ReferrerContext.Provider value={{}}>{children}</ReferrerContext.Provider>
  );
};

export { ReferrerContext, ReferrerContextProvider };
