import http from './httpService';
import { BufferDownload } from './downloadService';
import moment from 'moment';
import { User } from './userService';
import Config from 'config/Config';
import httpService from './httpService';
import _ from 'lodash';
const user = new User();

const orgId = user.getOrgID();
const baseUrl = `/user/cp/org/${orgId}/accounting/invoices`;
const creditNoteUrl = `/user/cp/org/${orgId}/accounting/credit-notes`;
const buffer = new BufferDownload();

export class InvoiceHandler {
  constructor() {
    this.VAT = user.getVAT();
  }
  bankDetails(data, callback) {
    callback((prev) => ({
      ...prev,
      bankDetails: {
        bankName: data?.payment?.defaultAccount?.bankName || '',
        accountName: data?.payment?.defaultAccount?.bankAccountName || '',
        accountNumber: data?.payment?.defaultAccount?.bankAccountNumber || '',
      },
      acceptsOnlinePayment: data?.payment?.isEnabled,
    }));
  }
  currency(defaultValues) {
    const rate = Number(defaultValues?.currencyRate);
    const prevRate = Number(defaultValues?.prevRate);
    const items = defaultValues.items?.map((item) => {
      let price = Number(item?.price);
      price = (price / prevRate) * rate;

      const vatAmount = item?.vat
        ? (this.VAT / 100) * (price * item?.quantity)
        : 0;
      const amount = item?.vat
        ? (this.VAT / 100) * (price * item?.quantity) + price * item?.quantity
        : price * item?.quantity;
      return { ...item, price, vatAmount, amount };
    });

    return items;
  }
  populateFields(data, callback) {
    callback((prev) => ({
      ...prev,
      customer: { id: data?.customerId, ...data?.customerMeta },
      note: data?.note || '',
      bankInfo: data?.bankDetails || '',
      number: data?.number || '',
      reference: data?.reference || '',
      acceptsOnlinePayment: data?.acceptsOnlinePayment || false,
      dueDate: data?.dueDate || new Date(),
      issueDate: data?.issueDate || new Date(),
      type: data?.discount?.type || 'PERCENTAGE',
      amount: data?.discount?.amount || '',
      calculatedAmount: data?.conversion?.discount?.calculatedAmount || '',
      subTotal: data?.conversion?.subTotal || '',
      vatTotal: data?.conversion?.vatTotal || '',
      total: data?.conversion?.total || '',
      totalReceivedAmount: 0,
      prevRate: Number(data?.currency?.rate),
      currencyRate: Number(data?.currency?.rate),
      currencyCode: data?.currency?.alphaCode,
      items: data?.items?.map((item) => {
        return item?.type === 'PRODUCT'
          ? {
              type: 'PRODUCT',
              name: item?.name,
              code: '',
              ID: item?.id,
              id: item?.productItemId,
              itemId: item?.itemId,
              productItemId: item?.productItemId,
              price: item?.price,
              vatAmount: item?.vatIncluded
                ? (this.VAT / 100) * (item?.price * item?.quantity)
                : 0,
              vat: item?.vatIncluded,
              quantity: item?.quantity,
              quantityReturned: '',
              updatedQuantity: item?.updatedQuantity,
              amount: item?.vatIncluded
                ? (this.VAT / 100) * (item?.price * item?.quantity) +
                  item?.price * item?.quantity
                : item?.price * item?.quantity,
            }
          : {
              type: 'SERVICE',
              name: item?.name,
              id: item?.id,
              ID: item?.id,
              itemId: item?.itemId,
              price: item?.price,
              quantity: item?.quantity,
              vatAmount: item?.vatIncluded
                ? (this.VAT / 100) * (item?.price * item?.quantity)
                : 0,
              vat: item?.vatIncluded ? true : false,
              quantityReturned: '',
              updatedQuantity: item?.updatedQuantity,
              amount: item?.vatIncluded
                ? (this.VAT / 100) * (item?.price * item?.quantity) +
                  item?.price * item?.quantity
                : item?.price * item?.quantity,
            };
      }),
    }));
  }
  selectItem(data, callback, rate) {
    callback((prev) => ({
      ...prev,
      items: [this.formatItemData(data, rate), ...prev.items],
    }));
  }
  selectService(data, callback, rate) {
    callback((prev) => ({
      ...prev,
      items: [this.formatServiceData(data, rate), ...prev.items],
    }));
  }
  removeItem(id, callback) {
    callback((prev) => ({
      ...prev,
      items: prev.items.filter((item) => item?.id !== id),
    }));
  }
  quantityReturned(e, id, callback) {
    callback((prev) => ({
      ...prev,
      items: prev?.items?.map((item) => {
        if (item?.id === id) {
          const { value } = e.target;
          const quantity =
            parseInt(value || 0) > item?.quantity ? '' : parseInt(value || 0);
          if (parseInt(value || 0) > item?.quantity) {
            const message = `Quantity returned should be less or equal to the initial quantity!`;
            httpService.toast.error(message);
          }

          return {
            ...item,
            quantityReturned:
              parseInt(value || 0) > item?.quantity ? '' : value,
            vatAmount: item?.vat
              ? (this.VAT / 100) *
                (item?.price * (item?.quantity - quantity || 0))
              : 0,
            amount: item?.vat
              ? (this.VAT / 100) * (item?.price * (item?.quantity - quantity)) +
                item?.price * (item?.quantity - quantity)
              : item?.price * (item?.quantity - quantity),
          };
        }
        return item;
      }),
    }));
  }

  updateItemQuantity(e, id, callback) {
    const { value } = e.target;
    const quantity = parseInt(value || 0);
    callback((prev) => ({
      ...prev,
      items: prev?.items?.map((item) => {
        const price = Number(item?.price);
        if (item?.id === id) {
          return {
            ...item,
            quantity: value,
            vatAmount: item?.vat ? (this.VAT / 100) * (price * quantity) : 0,
            amount: item?.vat
              ? (this.VAT / 100) * (price * quantity) + price * quantity
              : price * quantity,
          };
        }
        return item;
      }),
    }));
  }
  updateItemPrice(e, id, callback) {
    const { value } = e.target;
    const price = Number(value);
    callback((prev) => ({
      ...prev,
      items: prev?.items?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            price: value,
            vatAmount: item?.vat
              ? (this.VAT / 100) * (price * item.quantity)
              : 0,
            amount: item?.vat
              ? (this.VAT / 100) * (price * item.quantity) +
                price * item.quantity
              : price * item.quantity,
          };
        }
        return item;
      }),
    }));
  }

  updateItemVAT(e, id, callback) {
    const { checked: vat } = e.target;
    callback((prev) => ({
      ...prev,
      items: prev?.items?.map((item) => {
        if (item?.id === id) {
          const price = Number(item?.price);
          return {
            ...item,
            vat,
            vatAmount: vat ? (this.VAT / 100) * (price * item?.quantity) : 0,
            amount: vat
              ? (this.VAT / 100) * (price * item?.quantity) +
                price * item?.quantity
              : price * item?.quantity,
          };
        }
        return item;
      }),
    }));
  }
  updateServiceVAT(e, id, callback) {
    const { checked: vat } = e.target;
    callback((prev) => ({
      ...prev,
      items: prev?.items?.map((item) => {
        const price = Number(item?.price);
        if (item?.id === id) {
          return {
            ...item,
            vat,
            vatAmount: vat ? (this.VAT / 100) * (price * item?.quantity) : 0,
            amount: vat
              ? (this.VAT / 100) * (price * item?.quantity) +
                price * item?.quantity
              : price * item?.quantity,
          };
        }
        return item;
      }),
    }));
  }

  updateServiceQuantity(e, id, callback) {
    const { value } = e.target;
    const quantity = parseInt(value || 0);
    callback((prev) => ({
      ...prev,
      items: prev?.items?.map((item) => {
        const price = Number(item?.price);
        if (item?.id === id) {
          return {
            ...item,
            quantity: value,
            vatAmount: item?.vat ? (this.VAT / 100) * (price * quantity) : 0,
            amount: item?.vat
              ? (this.VAT / 100) * (price * quantity) + price * quantity
              : price * quantity,
          };
        }
        return item;
      }),
    }));
  }

  updateServicePrice(e, id, callback) {
    const { value } = e.target;
    const price = Number(value || 0);
    callback((prev) => ({
      ...prev,
      items: prev?.items?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            price: value,
            vatAmount: item?.vat
              ? (this.VAT / 100) * (price * item.quantity)
              : 0,
            amount: item?.vat
              ? (this.VAT / 100) * (price * item.quantity) +
                price * item.quantity
              : price * item.quantity,
          };
        }
        return item;
      }),
    }));
  }
  amount(items, type, value) {
    let discountAmount = parseFloat(value || 0);
    const subTotal = items?.reduce(
      (acc, curr) => acc + (curr.quantity || 1) * curr?.price,
      0,
    );

    const VAT = items?.reduce((acc, curr) => acc + curr.vatAmount, 0);

    const discount =
      type === 'PERCENTAGE'
        ? (discountAmount / 100) * subTotal
        : discountAmount;

    const total = items?.reduce((acc, curr) => acc + curr.amount, 0) - discount;
    return {
      VAT: VAT || 0,
      total: total || 0,
      subTotal: subTotal || 0,
      discount: discount || 0,
    };
  }
  formatItemData(data, rate) {
    let price = Number(data?.units[0]?.sellingPrice);
    price = +(price * rate);

    return {
      type: 'PRODUCT',
      name: data?.mainName ? `${data?.mainName} -${data?.name}` : data?.name,
      id: data?.id,
      itemId: data?.itemId,
      productItemId: data?.productItemId,
      isVariant: data?.isVariant || false,
      price: price,
      vatAmount: data?.vatInclusive ? (this.VAT / 100) * price : 0,
      vat: data?.vatInclusive,
      quantity: 1,
      amount: data?.vatInclusive ? (this.VAT / 100) * price + price : price,
    };
  }
  formatServiceData(data, rate) {
    let price = Number(data?.price);
    price = +(price * rate);

    return {
      type: 'SERVICE',
      name: data?.name,
      id: data?.id,
      itemId: data?.id,
      price: price,
      vatAmount: data?.vatExempted === 'NO' ? (this.VAT / 100) * price : 0,
      vat: data?.vatExempted === 'NO' ? true : false,
      quantity: 1,
      amount:
        data?.vatExempted === 'NO' ? (this.VAT / 100) * price + price : price,
    };
  }
}

class InvoiceService extends InvoiceHandler {
  getInvoices(query) {
    const params = {};
    const df = 'YYYY-MM-DD';
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.status) params.status = query?.status;
    if (query?.customerId) params.customerId = query?.customerId;
    if (query?.dateFrom) params.dateFrom = moment(query?.dateFrom).format(df);
    if (query?.dateTo) params.dateTo = moment(query?.dateTo).format(df);
    return http.get(baseUrl, { params });
  }
  getOneInvoice(id) {
    return http.get(`${baseUrl}/single`, { params: { id } });
  }
  getCustomerInvoices(query) {
    const params = {};
    if (query.page) params.page = query.page;
    if (query.customerId) params.customerId = query.customerId;
    return http.get(baseUrl, { params });
  }
  createInvoice(data, id = '', query = { action: '' }) {
    const { type, amount, items } = data;
    const { discount } = this.amount(items, type, amount);
    const formData = {
      items: items?.map((item) => {
        const fields = ['ID', 'quantityReturned', 'updatedQuantity'];
        const data = _.omit(item, fields);
        data.vatIncluded = item?.vat;
        data.productItemId = item?.id;
        return data;
      }),
      customerId: data?.customer?.id,
      number: data?.number,
      acceptsOnlinePayment: data?.acceptsOnlinePayment,
      dueDate: data?.dueDate,
      issueDate: data?.issueDate,
      note: data?.note,
      bankDetails: data?.bankInfo
        ? data?.bankInfo
        : data?.bankDetails?.bankName
        ? `${data?.bankDetails?.bankName}-${data?.bankDetails?.accountName}-${data?.bankDetails?.accountNumber}`
        : null,
      reference: data?.reference,
      discount: {
        type: data?.type,
        amount: data?.amount || 0,
        calculatedAmount: discount,
      },
      currency: {
        alphaCode: data?.currencyCode,
        rate: data?.currencyRate,
      },
    };

    Object.keys(formData).forEach((key) => {
      if (!formData[key]) delete formData[key];
    });
    const { number, ...rest } = formData;

    const params = {};
    if (id) params.id = id;
    if (query.action) params.action = query.action;

    return id
      ? http.put(baseUrl, rest, { params })
      : http.post(baseUrl, formData, { params });
  }
  deleteInvoice(id) {
    return http.delete(baseUrl, { params: { id } });
  }
  duplicateInvoice(id) {
    return http.post(`${baseUrl}/duplicate`, {}, { params: { id } });
  }
  downloadInvoice(id, callback) {
    return buffer.download(`${baseUrl}/download?id=${id}`, 'Invoice', callback);
  }
  issueInvoice(id) {
    return http.put(`${baseUrl}/issue`, {}, { params: { id } });
  }
  recordPayment(data, invoiceId) {
    const formData = {
      ...data,
      amount: parseFloat(data?.amount),
      withHoldingTax: parseFloat(data?.withHoldingTax),
    };
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) delete formData[key];
    });
    return http.post(`${baseUrl}/payments`, formData, {
      params: { invoiceId },
    });
  }
  sendInvoice(data, id) {
    return http.post(`${baseUrl}/send`, data, { params: { id } });
  }
  sendInvoiceReceipt(data, id) {
    return http.post(`${baseUrl}/send-receipt?id=${id}`, data);
  }
  getCreditNotes(query) {
    const params = {};
    const df = 'YYYY-MM-DD';
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.status) params.status = query?.status;
    if (query?.customerId) params.customerId = query?.customerId;
    if (query?.dateFrom) params.dateFrom = moment(query?.dateFrom).format(df);
    if (query?.dateTo) params.dateTo = moment(query?.dateTo).format(df);
    return http.get(creditNoteUrl, { params });
  }
  getCreditNote(id) {
    return http.get(`${creditNoteUrl}/single?id=${id}`);
  }
  createCreditNote(data, id) {
    const formData = {
      invoiceId: id,
      items: data?.items
        ?.map((item) => ({
          id: item?.ID,
          quantity: parseInt(item?.quantityReturned || 0),
        }))
        ?.filter((item) => item?.quantity > 0),
      issuedAt: data?.issueDate,
    };
    return http.post(creditNoteUrl, formData);
  }
  nextNumber() {
    return http.get(baseUrl + '/next-number');
  }
  getPublicInvoice(publicId) {
    return http.get(`${Config.baseUrl}/public/invoice/${publicId}`);
  }
  makePayment(publicId) {
    return http.post(`${Config.baseUrl}/public/invoice/payment/${publicId}`, {
      service: 'PAYSTACK',
    });
  }
}

export default new InvoiceService();
