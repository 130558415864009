import React from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import httpService from 'service/httpService';
import userService from 'service/userService';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdatePasswordSchema } from 'site-constants';
import BTNLoader from 'lib/components/Preloaders/Btn';

const PasswordAndSecurity = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(UpdatePasswordSchema.schema),
    mode: 'all',
    defaultValues: {
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  return (
    <div>
      <div className="box2" style={{ padding: '20px' }}>
        <p style={{ fontWeight: 'bold', fontSize: 20 }}>Change password</p>
        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid item xs={12} sm={6} md={6}>
            <form onSubmit={handleSubmit(save)}>
              <div className="space-bottom">
                <div style={{ marginTop: '16px' }} className="text">
                  Current password
                </div>
                <Controller
                  name="password"
                  control={control}
                  render={(props) => (
                    <TextField
                      fullWidth
                      {...props}
                      type="password"
                      size="small"
                      variant="outlined"
                      placeholder="********"
                      inputRef={props?.ref}
                      error={!!errors['password']}
                      helperText={errors['password']?.message}
                    />
                  )}
                />
              </div>
              <div className="space-bottom">
                <div style={{ marginTop: '16px' }} className="text">
                  New password
                </div>
                <Controller
                  name="newPassword"
                  control={control}
                  render={(props) => (
                    <TextField
                      fullWidth
                      {...props}
                      type="password"
                      size="small"
                      variant="outlined"
                      placeholder="********"
                      inputRef={props?.ref}
                      error={!!errors['newPassword']}
                      helperText={errors['newPassword']?.message}
                    />
                  )}
                />
              </div>
              <div className="space-bottom">
                <div style={{ marginTop: '16px' }} className="text">
                  Confirm new password
                </div>
                <Controller
                  name="confirmNewPassword"
                  control={control}
                  render={(props) => (
                    <TextField
                      fullWidth
                      {...props}
                      type="password"
                      size="small"
                      variant="outlined"
                      placeholder="********"
                      inputRef={props?.ref}
                      error={!!errors['confirmNewPassword']}
                      helperText={errors['confirmNewPassword']?.message}
                    />
                  )}
                />
              </div>

              <Button
                disabled={isLoading}
                startIcon={isLoading && <BTNLoader />}
                color="primary"
                variant="contained"
                type="submit">
                Save
              </Button>
              <Button
                component={Link}
                to="/dashboard/settings"
                className="space-left2"
                variant="outlined">
                Cancel
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  async function save(data) {
    try {
      setIsLoading(true);
      await userService.changePassword(data);
      setIsLoading(false);
      httpService.toast.success('Password changed.');
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }
};

export default PasswordAndSecurity;
