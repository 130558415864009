const initialState = {
  user: {
    organizations: {
      data: [],
      lastFetch: null,
      refresh: false,
    },
    invitations: {
      data: [],
      lastFetch: null,
      refresh: false,
    },
    currentOrg: {
      lastFetch: null,
      data: null,
      refresh: false,
      loading: false,
    },
  },
  invoice: {
    invoices: {
      page: null,
      totalPages: null,
      list: [],
      limit: null,
      totalDocs: null,
      lastFetch: null,
      refresh: false,
    },
    creditNotes: {
      page: null,
      totalPages: null,
      list: [],
      limit: null,
      totalDocs: null,
      lastFetch: null,
      refresh: false,
    },
  },
  customers: [],
  singleCustomer: null,
  singleInvoice: null,
  singleCreditNote: null,
  singleOutflow: null,
  singleVendor: null,
  inflow: {
    inflows: [],
  },
  outflow: { outflows: [] },
  inventory: {
    products: [],
    services: [],
  },
  quotation: {
    quotations: [],
  },
  transaction: {
    transactions: [],
    reconciliations: [],
    lines: [],
  },
  vendor: {
    vendors: [],
  },
  chartOfAcc: {
    chartOfAccs: [],
  },
  fixedAssets: {},
  budget: {
    budgets: [],
  },
  employees: {
    page: null,
    totalPages: null,
    list: [],
    limit: null,
    totalDocs: undefined,
    lastFetch: null,
    refresh: false,
  },
  singleEmployee: {},
  departments: {
    page: null,
    totalPages: null,
    list: [],
    limit: null,
    totalDocs: null,
    lastFetch: null,
    refresh: false,
  },
  branches: {
    page: null,
    totalPages: null,
    list: [],
    limit: null,
    totalDocs: null,
    lastFetch: null,
    refresh: false,
  },
  leave: {
    pending: [],
    accepted: [],
    rejected: [],
  },
  payroll: {
    payrolls: [],
    payslips: [],
  },

  query: {
    pending: [],
    closed: [],
  },
  transfer: { transfers: [] },
  suspension: {
    ongoing: [],
    reinstated: [],
  },
  promotion: {
    promotions: [],
  },
  terRetirement: {
    terminations: [],
    retirements: [],
  },
  memo: { memos: [] },
  meeting: { meetings: [] },
  subscription: {
    plans: {
      list: [],
      lastFetch: null,
      refresh: false,
    },
    currentPlan: {
      plan: {},
      lastFetch: null,
      refresh: false,
    },
    receipts: {
      page: null,
      totalPages: null,
      list: [],
      limit: null,
      totalDocs: null,
      lastFetch: null,
      refresh: false,
    },
  },
};

export default initialState;
