import React from 'react';
import { Avatar, CircularProgress, Grid } from '@material-ui/core';
import 'index.css';
import { moneyFormat } from 'lib/helpers/formatCurrency';
import { Button } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import invoiceService from 'service/invoiceService';
import httpService from 'service/httpService';
import { MessageFeedback } from 'modules/components/Feedback/Feedback';
import Menu from 'lib/components/Menu/Menu';
import PreloaderInvoice from 'lib/components/Preloaders/InvoiceLoader';
import useInternetConnection from 'hooks/useInternetConnection';

const PayNow = ({ type = 'Invoice' }) => {
  const { id } = useParams();
  const { isOnline } = useInternetConnection();
  const [invoice, setInvoice] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [feedback, setFeedback] = useState({
    severity: '',
    message: '',
    open: false,
  });
  const d = invoice;
  const accentColor = invoice?.accentColor;
  const currency = invoice?.currency?.alphaCode;
  const subTotal = moneyFormat(invoice?.subTotal, currency);
  const vatTotal = moneyFormat(invoice?.vatTotal, currency);
  const total = moneyFormat(invoice?.total, currency);

  useEffect(() => {
    getPublicInvoice();
  }, [isOnline]);

  async function getPublicInvoice() {
    try {
      setIsFetching(true);
      const { data } = await invoiceService.getPublicInvoice(id);
      setInvoice(data?.data);
      setIsFetching(false);
    } catch (ex) {
      setIsFetching(false);
      httpService.showFeedback(ex, setFeedback);
      setShowError(true);
    }
  }

  async function makePayment() {
    try {
      setIsLoading(true);
      const { data } = await invoiceService.makePayment(id);
      location.replace(data?.data?.url);
      setIsLoading(false);
    } catch (ex) {
      httpService.showFeedback(ex, setFeedback);
      setIsLoading(false);
    }
  }
  const Loader = () => (
    <CircularProgress color="#f1f1f1" style={{ width: 30, height: 30 }} />
  );

  const PaymentMark = () => {
    if (d?.status !== 'PAID') return null;
    return (
      <div className="d-flex justify-content-end">
        <div
          style={{ width: 100 }}
          className="bg-success p-2 text-white fw-medium text-center rounded">
          PAID
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Menu />
      <div
        className="p-5 overflow-auto my-3 mx-auto"
        style={{ maxWidth: 1000 }}>
        {isFetching && <PreloaderInvoice />}
        {!d ? null : <div className="invoiceWrapper">{getInvoice()}</div>}
      </div>
    </React.Fragment>
  );

  function getInvoice() {
    switch (invoice?.templateOption) {
      case 'A':
        return tempA();
      case 'B':
        return tempB();
      case 'C':
        return tempC();
      case 'D':
        return tempD();
      case 'E':
        return tempE();
      default:
        return null;
    }
  }
  function getTable() {
    return (
      <div>
        <Grid container className="space-top">
          <Grid item xs={4}>
            <span className="text2 bold">Item</span>
          </Grid>
          <Grid item xs={2}>
            <span className="text2 bold">Quantity</span>
          </Grid>
          <Grid item xs={3} className="push-right">
            <span className="text2 bold">Price</span>
          </Grid>
          <Grid item xs={3} className="push-right">
            <span className="text2 bold ">Amount</span>
          </Grid>
        </Grid>
        <hr className="hr3" />
        {d?.items?.map((item, index) => {
          return (
            <div key={index} style={{ padding: '2px 0px' }}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <span className="text3">{item?.name}</span>
                </Grid>
                <Grid item xs={2}>
                  <span className="text3">{item?.quantity}</span>
                </Grid>
                <Grid item xs={3} className="push-right">
                  <span className="text3">
                    {moneyFormat(item?.price, currency)}
                  </span>
                </Grid>
                <Grid item xs={3} className="push-right">
                  <span className="text3">
                    {moneyFormat(item?.amount, currency)}
                  </span>
                </Grid>
              </Grid>
              <hr className="hr" />
            </div>
          );
        })}
      </div>
    );
  }

  function getDiscount() {
    return moneyFormat(d?.discount, currency);
  }

  function getBank(type = 1) {
    const bankName = d?.organization?.bank?.name;
    const accountName = d?.organization?.bank?.accountName;
    const accountNumber = d?.organization?.bank?.accountNumber;

    function form(d1, d2) {
      return (
        <div
          style={{
            display: 'flex',
            marginBottom: 10,
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 16,
          }}>
          <div>
            <b>{d1}:</b>
          </div>
          <div style={{ textAlign: 'end' }}>{d2}</div>
        </div>
      );
    }

    return (
      <div
        className="text sm space-top2"
        style={{
          padding: '20px',
          background: '#F9F9F9',
        }}>
        {form('Bank', bankName)}
        {form('Account Name', accountName)}
        {form('Account Number', accountNumber)}
      </div>
    );
  }

  function getLogo() {
    return (
      <div>
        <Avatar
          style={{
            width: 120,
            height: 120,
            background: d?.organization?.logoURL ? '' : '#1f53d7',
            fontSize: 48,
          }}
          src={d?.organization?.logoURL}>
          {d?.organizationMeta?.name.charAt(0).toUpperCase()}
          {d?.organizationMeta?.name.charAt(1).toUpperCase()}
        </Avatar>
      </div>
    );
  }

  function tempA() {
    return (
      <div className="invoiceBox2 position-relative overflow-hidden">
        <div style={{ padding: '30px' }}>
          <PaymentMark />
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div>
                {getLogo()}

                <div style={{ marginTop: '100px' }}>
                  <div className="text2 md bold space-bottom2">Bill To:</div>
                  <div className="text2 space-bottom2">{d?.customer?.name}</div>
                  <div className="text2 space-bottom2">
                    {d?.customer?.address}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="shift-right">
                <div className="text2 bold " style={{ fontSize: '40px' }}>
                  {type}
                </div>
                <div className="text2 bold" style={{ fontSize: '22px' }}>
                  {d?.organization?.name}
                </div>
                <div className="text2">{d?.organization?.address}</div>
                <div style={{ marginTop: '50px' }}>
                  <div className="text2 space-bottom2">
                    {type} No: #{d?.number}
                  </div>
                  <div className="text2 space-bottom2">
                    Issued Date: {d?.issueDate}
                  </div>
                  <div className="text2 space-bottom2">
                    Due Date: {d?.dueDate}
                  </div>
                  <div className="text2 bold space-bottom2">
                    {/* Grand Total:
                          {d.total} */}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          {getTable()}

          <div style={{ marginTop: '50px' }}>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={9} className="push-right">
                <span className="text bold">Sub Total:</span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text3">{subTotal}</span>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={9} className="push-right">
                <span className="text bold">Discount:</span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text3">{getDiscount()}</span>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={9} className="push-right">
                <span className="text bold">VAT:</span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text3">{vatTotal}</span>
              </Grid>
            </Grid>
          </div>
          <hr className="hr3" />

          <Grid container spacing={1} className="space-top">
            <Grid item xs={3}></Grid>
            <Grid item xs={6} className="push-right">
              <span className="text bold">Total:</span>
            </Grid>
            <Grid item xs={3} className="push-right">
              <span className="text3">{total}</span>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              <div className="text bold md">Bank Details:</div>
              <div className="text sm">{getBank()}</div>
              <div className="text md bold space-top">Terms/Notes:</div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              <div
                className="space-top2"
                style={{
                  width: '70%',
                  padding: '20px',
                  background: '#F9F9F9',
                }}>
                <span className="text italic sm light">
                  {d?.note === '0' ? '' : d?.note}
                </span>
              </div>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            background: accentColor || '#336666',
            padding: '40px 20px',
          }}>
          <div className="white shift-right">
            <span className="text2 white bold">Grand Total:</span>
            <hr className="space-y hr2" />
            <div
              className="text3 space-top white space-bottom2"
              style={{ fontSize: '45px', lineHeight: '30px' }}>
              {total}
            </div>
            <div className="text2 sm white" style={{ display: 'none' }}>
              Total payment due in.
            </div>
            <hr className="space-y hr2" />
          </div>

          <div className="push-left-right center2">
            <div className="ruby">
              <span
                style={{
                  padding: '15px',
                  background: '#fff',
                  borderRadius: '50px',
                }}></span>
              <span className="text white space-left2">Thank you!</span>
            </div>
          </div>
        </div>
        <div style={{ maxWidth: 500, width: '100%', margin: '30px auto' }}>
          {d?.status === 'PAID' ? (
            <Button disabled type="button" variant="contained">
              paid
            </Button>
          ) : (
            <Button
              disabled={isLoading || d?.status === 'PAID'}
              type="button"
              variant="contained"
              onClick={makePayment}
              startIcon={isLoading && <Loader />}
              style={{
                width: '100%',
                height: '50px',
                background: accentColor || '#336666',
                color: 'white',
              }}>
              Pay Now
            </Button>
          )}
        </div>
      </div>
    );
  }

  function tempB() {
    return (
      <div className="invoiceBox2 position-relative overflow-hidden">
        <div style={{ padding: '30px' }}>
          <PaymentMark />
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <div>
                <div className="text2 bold" style={{ fontSize: '22px' }}>
                  {d?.organization?.name}
                </div>
                <div className="text2">{d?.organization?.address}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="center2">{getLogo()}</div>
            </Grid>
            <Grid item xs={4}>
              <div
                className="text2 bold shift-right"
                style={{ fontSize: '25px' }}>
                {type}
              </div>
              <div
                className="text2 bold shift-right"
                style={{ fontSize: '25px' }}>
                #{d?.number}
              </div>
            </Grid>
          </Grid>
          <hr className="hr space-top space-bottom" />
          <Grid container className="space-top">
            <Grid item xs={4}>
              <div className="text2 md bold space-bottom2">
                Client Contact Info:
              </div>
              <div className="text2 sm space-bottom2 light">
                {d?.customer?.name}
              </div>
              <div className="text2 sm space-bottom2 light">
                {d?.customer?.email}
              </div>
              <div className="text2 sm space-bottom2 light">
                {d?.customer?.phone}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <div className="text2 md bold space-bottom2">Bill To:</div>
                <div className="text2 sm light space-bottom2">
                  {d?.customer?.name}
                </div>
                <div className="text2 sm space-bottom2">
                  {d?.customer?.address}
                </div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="push-left-right space-bottom2">
                <div className="text2 sm light">Issued Date:</div>
                <div className="text2 sm light">{d?.issueDate}</div>
              </div>
              <div className="push-left-right space-bottom2">
                <div className="text2 sm light">Due Date:</div>
                <div className="text2 sm light">{d?.dueDate}</div>
              </div>
              <div className="push-left-right space-bottom2">
                <div className="text2 sm light">Amount Due:</div>
                <div className="text2 sm light">{total}</div>
              </div>
            </Grid>
          </Grid>

          <hr className="hr space-top space-bottom" />

          <div>
            <Grid container className="space-top">
              <Grid item xs={9}>
                <span className="text2 bold">Charges</span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text2 bold ">Amount</span>
              </Grid>
            </Grid>
            <hr />
            {d?.items?.map((item, index) => {
              return (
                <div key={index}>
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <span className="text3">{item?.name}</span>
                    </Grid>
                    <Grid item xs={3} className="push-right">
                      <span className="text3">
                        {moneyFormat(item?.amount, currency)}
                      </span>
                    </Grid>
                  </Grid>
                  <hr className="hr" />
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: '20px' }}>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={9} className="push-right">
                <span className="text bold">Sub Total:</span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text3">{subTotal}</span>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={9} className="push-right">
                <span className="text bold">Discount:</span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text3">{getDiscount()}</span>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={9} className="push-right">
                <span className="text bold">VAT:</span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text3">{vatTotal}</span>
              </Grid>
            </Grid>
          </div>
          <hr style={{ marginBottom: '15px' }} />

          <Grid container spacing={1} className="space-top">
            <Grid item xs={3}></Grid>
            <Grid item xs={6} className="push-right">
              <span className="text bold">Total:</span>
            </Grid>
            <Grid item xs={3} className="push-right">
              <span className="text3 bold ">{total}</span>
            </Grid>
          </Grid>

          <hr style={{ marginTop: '20px', marginBottom: '20px' }} />

          <Grid container>
            <Grid item xs={6}>
              <div className="text bold md">Payments:</div>
              <div className="text sm">{getBank()}</div>
              <div className="text md bold space-top">Terms/Notes:</div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={8}>
              <div
                className="space-top2"
                style={{
                  width: '70%',
                  padding: '20px',
                  background: '#F9F9F9',
                }}>
                <span className="text italic sm light">
                  {d?.note === '0' ? '' : d?.note}
                </span>
              </div>
            </Grid>
          </Grid>
          <div style={{ maxWidth: 500, width: '100%', margin: '30px auto' }}>
            {d?.status === 'PAID' ? (
              <Button disabled type="button" variant="contained">
                paid
              </Button>
            ) : (
              <Button
                disabled={isLoading || d?.status === 'PAID'}
                type="button"
                variant="contained"
                onClick={makePayment}
                style={{
                  height: '50px',
                  background: accentColor || 'black',
                  color: 'white',
                  width: '100%',
                }}>
                Pay Now
              </Button>
            )}
          </div>
        </div>

        <hr style={{ marginTop: '20px' }} />

        <div style={{ padding: '30px' }}>
          <Grid container spacing={2}>
            <Grid xs={4} item>
              <div className="text bold md">Payments:</div>
              <div className="text sm">Make check payable to:</div>
              <div>
                <div className="text sm"> {d?.organization?.name} </div>
                <div className="text2 sm">{d?.organization?.address}</div>
              </div>
            </Grid>
            <Grid xs={4} item>
              <div className="text bold md">Questions:</div>
              <div>
                <div className="text sm"> {d?.organization?.name} </div>
                <div className="text2 sm">
                  {d?.organization?.contact?.phone}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  function tempC() {
    return (
      <div
        style={{ borderLeft: `25px solid ${accentColor || '#0292B3'}` }}
        className="invoiceBox2 position-relative overflow-hidden">
        <div style={{ padding: '30px' }}>
          <PaymentMark />
          <div className="push-left-right">
            <div className="ruby">
              {getLogo()}
              <span
                className="text2 bold space-left"
                style={{ fontSize: '40px' }}>
                {type}
              </span>
            </div>
            <div className="ruby">
              <div>
                <div>{type?.toUpperCase()} NO.</div>
                <div>#{d?.number}</div>
              </div>
              <div className="space-left">
                <div>{type?.toUpperCase()} DATE</div>
                <div>{d?.dueDate}</div>
              </div>
            </div>
          </div>
          <div className="push-left-right space-top">
            <div>
              <div className="text2 bold " style={{ fontSize: '20px' }}>
                RECIPIENT
              </div>
              <div className="text2 space-bottom2">{d?.customer?.name}</div>
              <div className="text2 space-bottom2">{d?.customer?.address}</div>
              <div className="text2 space-bottom2">{d?.customer?.email}</div>
              <div className="text2 space-bottom2">{d?.customer?.phone}</div>
            </div>

            <div className="shift-right">
              <div className="text2 space-bottom2">{d?.organization?.name}</div>
              <div className="text2 space-bottom2">
                {d?.organization?.address}
              </div>
              <div className="text2 space-bottom2">
                {d?.organization?.contact?.phone}
              </div>
            </div>
          </div>

          <div>
            <Grid container className="space-top space-bottom2">
              <Grid item xs={6}>
                <span
                  className="text2 bold"
                  style={{ color: accentColor || '#0292B3' }}>
                  Description
                </span>
              </Grid>
              <Grid item xs={2}>
                <span
                  className="text2 bold"
                  style={{ color: accentColor || '#0292B3' }}>
                  Quantity
                </span>
              </Grid>
              <Grid item xs={2} className="push-right">
                <span
                  className="text2 bold"
                  style={{ color: accentColor || '#0292B3' }}>
                  Price
                </span>
              </Grid>
              <Grid item xs={2} className="push-right">
                <span
                  className="text2 bold "
                  style={{ color: accentColor || '#0292B3' }}>
                  Amount
                </span>
              </Grid>
            </Grid>

            {d?.items?.map((item, index) => {
              return (
                <div key={index}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <span className="text3">{item?.name}</span>
                    </Grid>
                    <Grid item xs={2}>
                      <span className="text3">
                        {item?.type !== 'SERVICE' && item?.quantity}
                      </span>
                    </Grid>
                    <Grid item xs={2} className="push-right">
                      <span className="text3">
                        {moneyFormat(item?.price, currency)}
                      </span>
                    </Grid>
                    <Grid item xs={2} className="push-right">
                      <span className="text3">
                        {moneyFormat(item?.amount, currency)}
                      </span>
                    </Grid>
                  </Grid>
                  <hr className="hr" />
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: '50px' }}>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <span
                  className="text bold"
                  style={{ color: accentColor || '#0292B3' }}>
                  Sub Total:
                </span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text3">{subTotal}</span>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <span
                  className="text bold"
                  style={{ color: accentColor || '#0292B3' }}>
                  Discount:
                </span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text3">{getDiscount()}</span>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={6}></Grid>
              <Grid item xs={3}>
                <span
                  className="text bold"
                  style={{ color: accentColor || '#0292B3' }}>
                  VAT:
                </span>
              </Grid>
              <Grid item xs={3} className="push-right">
                <span className="text3">{vatTotal}</span>
              </Grid>
            </Grid>
          </div>
          <hr className="hr" />

          <Grid container spacing={1} className="space-top">
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <span className="text bold">Total:</span>
            </Grid>
            <Grid item xs={3} className="push-right">
              <span
                className="text3"
                style={{ color: accentColor || '#0292B3' }}>
                {total}
              </span>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <div
                className="text bold md white"
                style={{
                  background: accentColor || '#0292B3',
                  padding: '5px',
                  marginTop: '30px',
                }}>
                ACCOUNT DATA:
              </div>
              <div className="text sm">
                Transfer the amount to the business account below. Please
                include invoice number on your check.
              </div>
              <div className="text sm">{getBank()}</div>
            </Grid>
          </Grid>
          <div className="text md bold space-top">NOTES:</div>
          <Grid container>
            <Grid item xs={8}>
              <div
                className="space-top2"
                style={{
                  width: '100%',
                  padding: '20px',
                  background: '#F9F9F9',
                }}>
                <span className="text italic sm light">
                  {d?.note === '0' ? '' : d?.note}
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ maxWidth: 500, width: '100%', margin: '30px auto' }}>
          {d?.status === 'PAID' ? (
            <Button disabled type="button" variant="contained">
              paid
            </Button>
          ) : (
            <Button
              disabled={isLoading || d?.status === 'PAID'}
              type="button"
              variant="contained"
              onClick={makePayment}
              style={{
                height: '50px',
                background: accentColor || '#0292B3',
                color: 'white',
                width: '100%',
              }}>
              Pay Now
            </Button>
          )}
        </div>

        <hr style={{ margin: '30px 0px' }} />

        <div style={{ padding: '0px 30px' }}>
          <Grid container>
            <Grid item xs={4}>
              <div className="text2 sm light ">{d?.organization?.name}</div>
              <div className="text2 sm light space-bottom">
                {d?.organization?.address}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="text2 sm light space-bottom">
                {d?.organization?.contact?.phone}
              </div>
            </Grid>
            <Grid item xs={4} className="push-right">
              <div className="text2 sm light">{type?.toUpperCase()} NO.</div>
              <div className="text2 sm light space-bottom">#{d?.number}</div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  function tempD() {
    return (
      <div className="invoiceBox2 position-relative overflow-hidden">
        <div style={{ padding: '30px' }}>
          <PaymentMark />
          <Grid container>
            <Grid item xs={8}>
              <div
                className="text2 bold space-top2"
                style={{ fontSize: '20px' }}>
                {type} for {total}
              </div>
              <div
                className="text2 bold space-top2"
                style={{ fontSize: '20px' }}>
                Due By {d?.dueDate}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="text2 space-bottom2 space-top2">
                {d?.organization?.name}
              </div>
              <div className="text2 space-bottom2">
                {d?.organization?.address}
              </div>
              <div className="text2 space-bottom2">
                {d?.organization?.contact?.phone}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <div
                className="text2 bold "
                style={{ fontSize: '20px', marginTop: '40px' }}>
                Billed to:
              </div>
              <div className="text2 space-bottom2">{d?.customer?.name}</div>
              <div className="text2 space-bottom2">{d?.customer?.address}</div>
              <div className="text2 space-bottom2">{d?.customer?.email}</div>
              <div className="text2 space-bottom2">{d?.customer?.phone}</div>
            </Grid>

            <Grid item xs={6}>
              <div>
                <div className="ruby space-top2">
                  <span className="bold text2">{type} number:</span>
                  <span className="space-left2">{d?.number}</span>
                </div>
              </div>
              <div>
                <div className="ruby space-top2">
                  <span className="bold text2">Issuing Date:</span>
                  <span className="space-left2">{d?.issueDate}</span>
                </div>
              </div>
            </Grid>
          </Grid>

          <div>
            <Grid container className="space-top">
              <Grid item xs={6}>
                <span className="text2 bold light">DESCRIPTION</span>
              </Grid>
              <Grid item xs={2}>
                <span className="text2 bold light">QUANTITY</span>
              </Grid>
              <Grid item xs={2} className="push-right">
                <span className="text2 bold light">COST</span>
              </Grid>
              <Grid item xs={2} className="push-right">
                <span className="text2 bold light ">AMOUNT</span>
              </Grid>
            </Grid>
            <hr className="hr" />
            {d?.items?.map((item, index) => {
              return (
                <div key={index}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <span className="text3">{item?.name}</span>
                    </Grid>
                    <Grid item xs={2}>
                      <span className="text3">
                        {item?.type !== 'SERVICE' && item?.quantity}
                      </span>
                    </Grid>
                    <Grid item xs={2} className="push-right">
                      <span className="text3">
                        {moneyFormat(item?.price, currency)}
                      </span>
                    </Grid>
                    <Grid item xs={2} className="push-right">
                      <span className="text3">
                        {moneyFormat(item?.amount, currency)}
                      </span>
                    </Grid>
                  </Grid>
                  <hr className="hr" />
                </div>
              );
            })}
          </div>

          <div style={{ marginTop: '50px' }}>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={10} className="push-right">
                <span className="text bold">Sub Total:</span>
              </Grid>
              <Grid item xs={2} className="push-right">
                <span className="text3">{subTotal}</span>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={10} className="push-right">
                <span className="text bold">Discount:</span>
              </Grid>
              <Grid item xs={2} className="push-right">
                <span className="text3">{getDiscount()}</span>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="space-top">
              <Grid item xs={10} className="push-right">
                <span className="text bold">VAT:</span>
              </Grid>
              <Grid item xs={2} className="push-right">
                <span className="text3">{vatTotal}</span>
              </Grid>
            </Grid>
          </div>

          <hr className="hr" />

          <Grid container spacing={1} className="space-top">
            <Grid item xs={3}></Grid>
            <Grid item xs={7} className="push-right">
              <span className="text bold">Total:</span>
            </Grid>
            <Grid item xs={2} className="push-right">
              <span className="text3">{total}</span>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              <div
                className="text sm"
                style={{
                  marginTop: '10px',
                  width: '100%',
                  padding: '20px',
                  background: '#F9FBFA',
                  borderRadius: '5px',
                }}>
                <div className="text bold md">Payment Details:</div>
                <div>{getBank()}</div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ maxWidth: 500, width: '100%', margin: '30px auto' }}>
          {d?.status === 'PAID' ? (
            <Button disabled type="button" variant="contained">
              paid
            </Button>
          ) : (
            <Button
              disabled={isLoading || d?.status === 'PAID'}
              type="button"
              variant="contained"
              onClick={makePayment}
              style={{
                height: '50px',
                background: '#111111',
                color: 'white',
                width: '100%',
              }}>
              Pay Now
            </Button>
          )}
        </div>
        <div
          className="center2"
          style={{ marginTop: '50px', marginBottom: '20px' }}>
          {getLogo()}
        </div>
      </div>
    );
  }

  function tempE() {
    return (
      <div className="invoiceBox2 position-relative overflow-hidden">
        <div>
          <div style={{ background: '#F8F7FA', padding: '30px' }}>
            <PaymentMark />
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <div>{getLogo()}</div>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={6}>
                <div>
                  <div className="text2 bold " style={{ fontSize: '30px' }}>
                    {type}
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <div className="text2 space-bottom2 bold">
                      {type} Number:
                    </div>
                    <div className="text2 space-bottom2">#{d?.number}</div>
                    <div className="text2 space-bottom2 bold">Date: </div>
                    <div className="text2 space-bottom2">{d?.issueDate}</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3} style={{ padding: '30px' }}>
            <Grid item xs={6}>
              <div style={{ marginTop: '20px' }}>
                <div className="text2 md bold space-bottom2">Bill To:</div>
                <div className="text2 space-bottom2">{d?.customer?.name}</div>
                <div className="text2 space-bottom2">
                  {d?.customer?.address}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={{ marginTop: '20px' }}>
                <div className="text2 md bold space-bottom2">Bill From:</div>
                <div className="text2 space-bottom2">
                  {d?.organization?.name}
                </div>
                <div className="text2">{d?.organization?.address}</div>
              </div>
            </Grid>
          </Grid>

          <div>
            <Grid
              container
              className="space-top"
              style={{ background: '#F8F7FA', padding: '10px 30px' }}>
              <Grid item xs={6}>
                <span className="text2 bold">ITEM</span>
              </Grid>
              <Grid item xs={2}>
                <span className="text2 bold">COST</span>
              </Grid>
              <Grid item xs={2}>
                <span className="text2 bold">QUANTITY</span>
              </Grid>
              <Grid item xs={2} className="push-right">
                <span className="text2 bold ">PRICE</span>
              </Grid>
            </Grid>
            <div style={{ padding: '0px 30px' }}>
              {d?.items?.map((item, index) => {
                return (
                  <div key={index}>
                    <Grid container>
                      <Grid item xs={6}>
                        <span className="text3">{item?.name}</span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className="text3">
                          {moneyFormat(item?.price, currency)}
                        </span>
                      </Grid>
                      <Grid item xs={2}>
                        <span className="text3">
                          {item?.type !== 'SERVICE' && item?.quantity}
                        </span>
                      </Grid>
                      <Grid item xs={2} className="push-right">
                        <span className="text3">
                          {moneyFormat(item?.amount, currency)}
                        </span>
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </div>
          </div>

          <div style={{ padding: '0px 30px' }}>
            <Grid container>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <div>
                  <hr className="hr3" />
                  <div style={{ marginTop: '10px' }}>
                    <Grid container spacing={1} className="space-top">
                      <Grid item xs={9}>
                        <span className="text ">Sub Total:</span>
                      </Grid>
                      <Grid item xs={3} className="push-right">
                        <span className="text3 push-right">{subTotal}</span>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="space-top">
                      <Grid item xs={9}>
                        <span className="text ">Discount:</span>
                      </Grid>
                      <Grid item xs={3} className="push-right">
                        <span className="text3 push-right">
                          {getDiscount()}
                        </span>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} className="space-top">
                      <Grid item xs={9}>
                        <span className="text ">VAT:</span>
                      </Grid>
                      <Grid item xs={3} className="push-right">
                        <span className="text3 push-right">{vatTotal}</span>
                      </Grid>
                    </Grid>
                  </div>
                  <hr className="hr3" />

                  <Grid container spacing={1} className="space-top">
                    <Grid item xs={9}>
                      <span className="text bold">Amount Due:</span>
                    </Grid>
                    <Grid item xs={3} className="push-right">
                      <span className="text3 push-right">{total}</span>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Grid container className="space-top">
              <Grid item xs={6}>
                <div className="text bold md">Payment Details:</div>
                <div>{getBank()}</div>
                <div className="text md bold space-top">Terms/Notes:</div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={8}>
                <div
                  className="space-top2"
                  style={{
                    width: '70%',
                    padding: '20px',
                    background: '#F9F9F9',
                  }}>
                  <span className="text italic sm light">
                    {d?.note === '0' ? '' : d?.note}
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>

          <div style={{ maxWidth: 500, width: '100%', margin: '30px auto' }}>
            {d?.status === 'PAID' ? (
              <Button disabled type="button" variant="contained">
                paid
              </Button>
            ) : (
              <Button
                disabled={isLoading || d?.status === 'PAID'}
                type="button"
                variant="contained"
                onClick={makePayment}
                style={{
                  height: '50px',
                  background: '#111111',
                  color: 'white',
                  width: '100%',
                }}>
                Pay Now
              </Button>
            )}
          </div>

          <div
            className="text push-left-right"
            style={{
              padding: '15px',
              background: '#F8F7FA',
              marginTop: '20px',
            }}>
            <div>
              <b>
                Thank you for being{' '}
                {/^[aeiou]/i.test(d?.organization?.name) ? 'an' : 'a'}{' '}
                {d?.organization?.name} customer :)
              </b>
            </div>
            <div>
              <b>Need help?</b>{' '}
              <span className="space-left2">{d?.organization?.phone}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PayNow;
