import { Button, IconButton } from '@material-ui/core';
import {
  ArrowBack,
  ArrowForward,
  FileCopy,
  FileCopyOutlined,
} from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Header from './components/header';
import { Alert } from '@material-ui/lab';
import { moneyFormat } from 'lib/helpers/formatCurrency';
import httpService from 'service/httpService';
import referrerService from 'service/referrerService';
import BTNLoader from 'lib/components/Preloaders/Btn';
import useUserData from 'hooks/useUserData';
import usePost from 'hooks/usePost';
import useFetch from 'hooks/useFetch';
import { FaRegEdit } from 'react-icons/fa';
import publicService from 'service/publicService';
import ViewCommissions from './components/commissions';

const initialValues = { code: '' };
const Invite = () => {
  const linkRef = React.useRef();
  const { user } = useUserData();
  const history = useHistory();
  const [values, setValues] = React.useState(initialValues);
  const [email, setEmail] = React.useState('');
  const [isEditCode, setIsEditCode] = React.useState(false);
  const [isCommissions, setIsCommissions] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState({
    link: false,
    code: false,
  });

  const profileQuery = useFetch({
    qKey: 'referral-profile',
    requestFn: () => referrerService.profile(),
    onSuccess: (data) => handleChange('code', data?.data?.code),
  });

  const plansQuery = useFetch({
    qKey: 'plans',
    requestFn: () => publicService.getPlans(),
  });

  const updateMutation = usePost({
    requestFn: (data) => referrerService.update(data),
    onSuccess: (data) => {
      setIsEditCode(false);
      profileQuery.refetch();
      httpService.toast.success('Referrer code updated successfully.');
    },
  });

  const profile = profileQuery?.data?.data || {};
  const isLoading = profileQuery.isLoading;
  const REFERRER_LINK = `${window.origin}?ref=${profile?.code}`;
  const MAIL_TEMPLATE = encodeURIComponent(generateMail());

  const plans = plansQuery?.data?.data;

  return (
    <div>
      <a
        ref={linkRef}
        className="d-none"
        href={`mailto:${email}?subject=Join Jureb Today!&body=${MAIL_TEMPLATE}`}
      />

      <Header
        title="Invite Businesses"
        subtitle="Invite new users from this page"
      />
      <div className="p-3">
        <div className="mb-4">
          <IconButton
            onClick={() => history.goBack()}
            style={{ background: '#EEF5FC' }}>
            <ArrowBack />
          </IconButton>
        </div>
        <div
          className="border rounded mb-5 p-3 mx-auto"
          style={{ maxWidth: 800 }}>
          <div className="mx-auto mt-3" style={{ maxWidth: 600 }}>
            <Alert severity="info" className="mb-4">
              <div className="fw-5 fw-medium">Info</div>
              <div>
                You only gain commissions on users who have subscribed to a
                package. Free Trial subscribers are not applicable.
              </div>
            </Alert>
            <div className="mb-3">
              To know how much commission you will earn click{' '}
              <span
                role="button"
                tabIndex={0}
                aria-label="Commisions earned per subscription"
                className="fw-medium text-primary text-decoration-underline"
                onClick={() => setIsCommissions(true)}>
                here
              </span>
            </div>
            <form onSubmit={handleSubmit} className="mb-5">
              <label htmlFor="email">
                Invite via email address <span className="text-danger">*</span>
              </label>
              <div className="input-group mb-3">
                <input
                  id="email"
                  required
                  type="email"
                  placeholder="johndoe@example.com"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  aria-label="Recipient's email address"
                />
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  endIcon={<ArrowForward />}>
                  Send Invite
                </Button>
              </div>
            </form>
            <div className="d-flex align-items-center gap-3 mb-5">
              <hr className="w-100 flex-grow-1" />
              Or
              <hr className="w-100 flex-grow-1" />
            </div>
            <div className="mb-5">
              <label htmlFor="share-link">Share referral link</label>
              <div className="input-group mb-3">
                <a
                  target="_blank"
                  rel="noreferrer"
                  id="share-link"
                  className="form-control text-primary"
                  href={`${window.origin}?ref=${profile?.code}`}
                  aria-label="Referral link">
                  {window.origin}?ref={profile?.code}
                </a>
                <Button
                  variant="contained"
                  onClick={() => copyToClipboard('share-link', 'link')}
                  startIcon={
                    isCopied.link ? <FileCopy /> : <FileCopyOutlined />
                  }>
                  {isCopied['link'] ? 'Copied' : 'Copy link'}{' '}
                </Button>
              </div>
            </div>

            <div className="mb-5 d-none">
              <label htmlFor="share-code">Share referral code</label>
              <div className="input-group mb-3">
                <div
                  id="share-code"
                  className="form-control"
                  aria-label="Referral Code">
                  {profile?.code}
                </div>
                <Button
                  variant="contained"
                  onClick={() => copyToClipboard('share-code', 'code')}
                  startIcon={<FileCopyOutlined />}>
                  {isCopied['code'] ? 'Copied' : 'Copy code'}
                </Button>
              </div>
            </div>
            <form onSubmit={save} className="mb-5">
              <label htmlFor="referral-code">
                Share referral code <span className="text-danger">*</span>
              </label>
              <div className="d-flex gap-3 align-items-center">
                <div className="input-group">
                  <input
                    required
                    type="text"
                    id="referral-code"
                    className="form-control"
                    readOnly={!isEditCode}
                    value={values.code}
                    onChange={(e) => handleChange('code', e.target.value)}
                    aria-label="Referral code"
                  />
                  {isEditCode ? (
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={updateMutation.isLoading}
                      startIcon={
                        updateMutation.isLoading ? <BTNLoader /> : null
                      }>
                      Save
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      title="Edit Referrer Code"
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditCode(true);
                        document.getElementById('referral-code').focus();
                      }}
                      startIcon={<FaRegEdit />}>
                      Edit
                    </Button>
                  )}
                </div>
              </div>
              <div className="text-secondary fs-sm fst-italic mb-3">
                You can customize your code to your preference by editing the
                field above
              </div>
            </form>
            <div className="mb-5">
              <div className="d-flex align-items-center gap-3 mb-3">
                <div className="text-secondary fw-medium text-nowrap">
                  Number of Businesses
                </div>
                <hr className="w-100 flex-grow-1" />
                <div className="fw-medium">{profile?.count?.referral || 0}</div>
              </div>
              <div className="d-flex align-items-center gap-3 mb-3">
                <div className="text-secondary fw-medium text-nowrap">
                  Invited Paid Businesses
                </div>
                <hr className="w-100 flex-grow-1" />
                <div className="fw-medium">{profile?.count?.paid || 0}</div>
              </div>
              <div className="d-flex align-items-center gap-3 mb-3">
                <div className="text-secondary fw-medium text-nowrap">
                  Total Earned
                </div>
                <hr className="w-100 flex-grow-1" />
                <div className="fw-medium">
                  {moneyFormat(profile?.total?.earned, 'NGN')}
                </div>
              </div>
            </div>
            <Alert severity="warning" className="mb-4 d-none">
              <div>
                Participation in the referral program implies agreement with the
                following terms: Referrers will receive payouts at the end of
                each month once a specified threshold is reached.
              </div>
            </Alert>
          </div>
        </div>
      </div>
      <ViewCommissions
        plans={plans}
        isOpen={isCommissions}
        onClose={() => setIsCommissions(false)}
      />
    </div>
  );

  function handleSubmit(e) {
    e.preventDefault();
    linkRef.current.click();
  }
  function save(e) {
    e.preventDefault();
    updateMutation.mutate(values);
  }

  function handleChange(name, value) {
    setValues((prev) => ({ ...prev, [name]: value }));
  }

  function copyToClipboard(id, type) {
    try {
      const text = document.getElementById(id).innerHTML;
      navigator.clipboard.writeText(text);
      setIsCopied((prev) => ({ ...prev, [type]: true }));
      setTimeout(() => {
        setIsCopied((prev) => ({ ...prev, [type]: false }));
      }, 1500);
    } catch (error) {
      console.error('CLIPBOARD BODY:', error);
    }
  }

  function generateMail() {
    return `Hi,
            I hope this email finds you well. I wanted to reach out to you to introduce you to JUREB, an amazing platform that I've been using recently.\n
            JUREB is a high-tech software that provides a birds eye view to allow unlimited growth and expansion for all businesses from nano to medium enterprises. The JUREB system has the capability to transform your current bookkeeping and human resource paper trails into a digitalized dashboard developed to simplify the complex operations necessary for overall business growth.\n
            I believe JUREB could be incredibly beneficial for you, feel free to check it out.\n
            Join Now: ${REFERRER_LINK}\n
            \n
            Best regards,
            ${user?.firstName} ${user?.lastName}\n
`;
  }
};

export default Invite;
