import { UserContext } from 'context/user/user';
import Cookies from 'js-cookie';
import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';
import { Redirect } from 'react-router-dom';

const NewInvites = () => {
  const { accessToken } = React.useContext(UserContext);

  if (accessToken) {
    return <Redirect to={{ pathname: PATHNAMES.ORGANIZATIONS }} />;
  }

  Cookies.set('new_invite', 'true', {
    expires: 1 / 8,
    sameSite: 'strict',
    secure: true,
  });
  return <Redirect to={{ pathname: PATHNAMES.SIGN_UP }} />;
};

export default NewInvites;
