import { yupResolver } from '@hookform/resolvers/yup';
import { UserContext } from 'context/user/user';
import BTNLoader from 'lib/components/Preloaders/Btn';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import httpService from 'service/httpService';
import userService from 'service/userService';
import { PersonalAccountSchema, PersonalProfileSchema } from 'site-constants';
import PhoneInput from 'react-phone-input-2';
import { Link } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import publicService from 'service/publicService';
import referrerService from 'service/referrerService';

const AccountDetails = () => {
  const [banks, setBanks] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFetchingDetails, setIsFetchingDetails] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const [isProfileFetching, setIsProfileFetching] = React.useState(false);
  const { handleSubmit, errors, control, reset, setValue, watch } = useForm({
    resolver: yupResolver(PersonalAccountSchema.schema),
    mode: 'all',
    defaultValues: {
      bankName: '',
      accountName: '',
      accountNumber: '',
    },
  });

  const bankName = watch('bankName');
  const accountNumber = watch('accountNumber');

  React.useEffect(() => {
    getBanks();
    getProfile();
  }, []);

  React.useEffect(() => {
    if (!profile) return;
    reset({
      bankName: profile?.bank?.name,
      accountName: profile?.bank?.accountName,
      accountNumber: profile?.bank?.accountNumber,
    });
    setBanks([
      {
        label: profile?.bank?.name,
        value: profile?.bank?.name,
      },
    ]);
  }, [profile]);
  return (
    <div>
      <div className="box2" style={{ padding: '20px' }}>
        <p style={{ fontWeight: 'bold', fontSize: 20 }}>Account Details</p>
        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          <Grid item xs={12} sm={6} md={6}>
            <form onSubmit={handleSubmit(save)}>
              <div className="space-bottom">
                <div className="mb-3">
                  <label htmlFor="Account Number" className="mb-1">
                    Account Number <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="accountNumber"
                    control={control}
                    render={(props) => (
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        value={props.value}
                        variant="outlined"
                        placeholder="XXX XXX XXX"
                        error={!!errors['accountNumber']}
                        helperText={errors['accountNumber']?.message}
                        onChange={(e) => {
                          const value = e.target.value;
                          props.onChange(value);
                          if (bankName && value?.length === 10) {
                            getAccountDetails(value, bankName);
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {isProfileFetching ? <BTNLoader /> : null}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Bank" className="mb-1">
                    Bank <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="bankName"
                    control={control}
                    render={(props) => (
                      <FormControl
                        error={!!errors['bankName']}
                        size="small"
                        fullWidth>
                        <Select
                          fullWidth
                          value={props.value}
                          variant="outlined"
                          onChange={(e) => {
                            const value = e.target.value;
                            props.onChange(value);
                            if (value && accountNumber?.length === 10) {
                              getAccountDetails(accountNumber, value);
                            }
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {isProfileFetching ? <BTNLoader /> : null}
                              </InputAdornment>
                            ),
                          }}>
                          <MenuItem value="" disabled className="px-3">
                            Select Bank
                          </MenuItem>
                          {banks?.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item?.name}
                              className="px-3">
                              {item?.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors['bankName']?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="Account Name" className="mb-1">
                    Account Name <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="accountName"
                    control={control}
                    render={(props) => (
                      <TextField
                        fullWidth
                        {...props}
                        type="text"
                        size="small"
                        variant="outlined"
                        inputRef={props?.ref}
                        error={!!errors['accountName']}
                        helperText={errors['accountName']?.message}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              {isFetchingDetails ? <BTNLoader /> : null}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </div>

              <Button
                disabled={isLoading}
                startIcon={isLoading && <BTNLoader />}
                color="primary"
                variant="contained"
                type="submit">
                Save
              </Button>
              <Button
                component={Link}
                to="/dashboard/settings"
                className="space-left2"
                variant="outlined">
                Cancel
              </Button>
            </form>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  async function save(data) {
    try {
      setIsLoading(true);
      await referrerService.updateBank(data);
      getProfile();
      setIsLoading(false);
      httpService.toast.success('Bank account details updated.');
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }

  async function getBanks() {
    try {
      const { data } = await publicService.getBanks();
      setBanks(data?.data);
    } catch (ex) {
      httpService.errorHandler(ex);
    }
  }

  async function getProfile() {
    try {
      setIsProfileFetching(true);
      const { data } = await referrerService.profile();
      setProfile(data?.data);
      setIsProfileFetching(false);
    } catch (error) {
      setIsProfileFetching(false);
      httpService.errorHandler(error);
    }
  }
  async function getAccountDetails(accountNumber, bankName) {
    try {
      setIsFetchingDetails(true);
      const query = { accountNumber, bankName };
      const { data } = await publicService.getBankAccountDetails(query);
      setIsFetchingDetails(false);
      setValue('accountName', data?.data?.account_name);
    } catch (ex) {
      setIsFetchingDetails(false);
      httpService.errorHandler(ex);
    }
  }
};

export default AccountDetails;
