import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const MessageFeedback = ({ message, severity, open, handleClose }) => {
  if (!message) return null;
  let errorMessage = '';
  if (Array?.isArray(message)) {
    errorMessage = Object.values(message[0])[0];
  } else if (typeof message === 'object') {
    errorMessage = Object.values(message)[0];
  } else if (typeof message === 'string' && message?.length < 255) {
    errorMessage = String(message);
  }
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      title={errorMessage}>
      <Alert severity={severity} className="fw-normal">
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};
