import axios from 'axios';
import Config from 'config/Config';
import http from './httpService';
import { User } from './userService';

const user = new User();
const OrgID = user.getOrgID();
const url = `/user/cp/org/${OrgID}/hr/performance-evaluations`;

class PerformanceHandler {
  constructor() {
    this.surveyQuestions = [
      {
        type: 'TEXT',
        title: 'Performance challenges encountered',
        mandatory: false,
        score: '',
      },
      {
        type: 'TEXT',
        title: 'Suggestions to remediate challenges.',
        mandatory: false,
        score: '',
      },
      {
        type: 'TEXT',
        title:
          'Areas for improvement focus (Skills/Performance/Behavioral gaps) for the period',
        mandatory: false,
        score: '',
      },
    ];
  }
  participantsFilterHandler(data = [], searchTerm) {
    let result = data;
    if (!searchTerm) return result;

    const cleanedSearchTerm = searchTerm
      ?.replace(/[^a-zA-Z0-9]/g, '')
      .toLowerCase();

    result = result.filter((item) => {
      const name = `${item?.employeeMeta?.firstName} ${item?.employeeMeta?.lastName}`;
      const cleanedName = name?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
      return cleanedName.includes(cleanedSearchTerm);
    });
    return result;
  }
  managersQuestions() {
    return [
      {
        id: 1,
        title: 'Job knowledge:',
        question: `Possesses requisite skills and Knowledge to perform the job.`,
        options: [5, 4, 3, 2, 1],
      },
      {
        id: 2,
        title: 'Result orientation:',
        question: `Continually seeks to accomplish critical task with measurable results. Overcome obstacles and makes adjustments to achieve results. Focuses self and others to achieve target aligned with business goals`,
        options: [5, 4, 3, 2, 1],
      },
      {
        id: 3,
        title: 'Quality:',
        question: `Completes work according to specification with minimum supervision. Thoroughly follows standards, procedures and keeps relevant records while paying attention to details`,
        options: [5, 4, 3, 2, 1],
      },
      {
        id: 4,
        title: 'Desire to improve:',
        question: `Continuously looks for ways to improve and promote quality. Applies feedback to improve performance.`,
        options: [5, 4, 3, 2, 1],
      },
      {
        id: 5,
        title: 'Professional impact:',
        question: `Makes an immediate and long-lasting positive impression on others. Has presence and comes across with credibility. Shows confidence in self and others.`,
        options: [5, 4, 3, 2, 1],
      },
      {
        id: 6,
        title: 'Ethics:',
        question: `Displays high level of professional attitude, integrity and encourages others to do same/ able to challenge unwholesome behavior.`,
        options: [5, 4, 3, 2, 1],
      },
      {
        id: 7,
        title: 'Initiative:',
        question: `Strive to learn and improve. Seeks out ways to improve. Does not remain idle. Hardworking and self-motivated.`,
        options: [5, 4, 3, 2, 1],
      },
      {
        id: 8,
        title: 'Communication',
        question: `Organizes and expresses idea clearly (both oral and written)`,
        options: [5, 4, 3, 2, 1],
      },
      {
        id: 9,
        title: 'Interpersonal skills',
        question: `Sensitive to the needs, feelings and capabilities of others, approachable in a non-threatening stance, has respect for others and a good mixer.`,
        options: [5, 4, 3, 2, 1],
      },
      {
        id: 10,
        title: 'Team player',
        question: `Contributes effectively when working in team to achieve group objective. Provides`,
        options: [5, 4, 3, 2, 1],
      },
    ];
  }
}

class PerformanceEvaluationService extends PerformanceHandler {
  create(data) {
    const formData = {
      ...data,
      questions: [...data?.questions, ...this.surveyQuestions]?.map(
        (item, index) => {
          const question = {};
          question.number = index + 1;
          question.type = item?.type;
          question.title = item?.title;
          question.mandatory = item?.mandatory;
          if (item.score) question.score = item.score;
          question.options =
            item?.type === 'RADIO'
              ? item?.options?.map(({ label, value }) => ({ label, value }))
              : [];
          return question;
        },
      ),
    };

    return http.post(url, formData);
  }

  get(query) {
    const params = {};
    if (query?.page) params.page = query.page;
    if (query?.keyword) params.keyword = query?.keyword;
    if (query?.limit) params.limit = query?.limit;
    return http.get(url, { params });
  }
  getSingleSurvey(id) {
    const params = {};
    if (id) params.id = id;
    return http.get(`${url}/single`, { params });
  }
  getEmployeeSurvey(id, evaluationId) {
    const params = { evaluationId, id };
    return http.get(`${url}/participants/single`, { params });
  }
  getPerticipants(evaluationId, query) {
    const params = { evaluationId };
    if (query?.page) params.page = query.page;
    if (query?.limit) params.limit = query.limit;
    if (query?.keyword) params.keyword = query.keyword;
    return http.get(`${url}/participants`, { params });
  }
  scoreParticipant(id, evaluationId, data) {
    const params = { id, evaluationId };
    return http.put(`${url}/participants/score`, data, { params });
  }
  search(query) {
    const params = { name: query };
    return http.get(`${url}/find`, { params });
  }
  delete(id) {
    return http.delete(url + '?id=' + id);
  }
  getPublicSurvey(evaluationId) {
    const path = `public/performance-evaluation/participants/${evaluationId}`;
    return axios.get(`${Config.baseUrl}/${path}`);
  }
  submitResponse(publicId, responses = []) {
    const formData = {
      answers: responses
        ?.map((item) => {
          const answer = { questionId: item?.id };
          if (item?.answer) answer.value = String(item?.answer);
          return answer;
        })
        .filter((item) => !!item?.value),
    };

    const path = `public/performance-evaluation/participants/${publicId}`;
    return axios.post(`${Config.baseUrl}/${path}`, formData);
  }
}

export default new PerformanceEvaluationService();
