import Cookies from 'js-cookie';

const saveToken = (data) => {
  if (!data) return;
  Cookies.set('_access_auth', data?.accessToken, {
    expires: 1 / 8,
    sameSite: 'strict',
    secure: true,
  });

  if (!data?.refreshToken) return;
  Cookies.set('_refresh_auth', data?.refreshToken, {
    expires: 6,
    sameSite: 'strict',
    secure: true,
  });
};

export default saveToken;
