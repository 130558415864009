import React from 'react';
import Header from './components/header';
import PersonsIcon from 'lib/assets/icons/referral__icon-3.svg';
import MoneyIcon from 'lib/assets/icons/referral__icon-2.svg';
import MoneyIconBlue from 'lib/assets/icons/referral__icon-5.svg';
import MoneyCashIcon from 'lib/assets/icons/referral__icon-1.svg';
import { moneyFormat } from 'lib/helpers/formatCurrency';
import useUserData from 'hooks/useUserData';
import {
  Button,
  Chip,
  FormControl,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from '@material-ui/core';
import { Add, SearchOutlined } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory, Link } from 'react-router-dom';
import { Alert, Pagination } from '@material-ui/lab';
import { PATHNAMES } from 'lib/constants/pathnames';
import moment from 'moment';
import scrollHandler from 'utils/scrollHandler';
import PreloaderTable from 'lib/components/Preloaders/Table';
import EmptyState from 'modules/components/EmptyState/EmptyState';
import referrerService from 'service/referrerService';
import useFetch from 'hooks/useFetch';

const tabList = [
  { label: 'All', value: 'ALL' },
  { label: 'Simple Start', value: 'SIMPLE_START' },
  { label: 'Standard', value: 'STANDARD' },
  { label: 'Premium', value: 'PREMIUM' },
];

const getAvailableBalance = (profile) => {
  const total = profile?.total;
  if (!total) return 0;
  return Number(total?.earned) - Number(total?.paid);
};

const Earnings = () => {
  const currency = 'NGN';
  const {} = useUserData();
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const plan = searchParams.get('plan');
  const page = searchParams.get('page') || 1;
  const status = searchParams.get('status');
  const dateFrom = searchParams.get('dateFrom') || null;
  const dateTo = searchParams.get('dateTo') || null;
  const keyword = searchParams.get('keyword');
  const query = { page, status, plan };

  const referrerProfileQuery = useFetch({
    qKey: 'my-referrer-profile',
    requestFn: () => referrerService.profile(),
  });
  const earningsQuery = useFetch({
    qKey: 'earnings',
    keys: [...Object.values(query)],
    requestFn: () => referrerService.getEarnings(query),
  });

  const profile = referrerProfileQuery?.data?.data;
  const earnings = earningsQuery?.data;
  const isLoading = earningsQuery.isLoading;

  return (
    <React.Fragment>
      <Header
        title="Earnings"
        subtitle="Manage all referrals earnings on this page"
      />
      <div className="p-3">
        <Alert severity="warning" className="mb-4">
          Participation in the referral program implies agreement with the
          following terms: Referrers will receive payouts at the end of each
          month once a specified threshold is reached.
        </Alert>
        <div className="row mb-3">
          <StatsCard
            title={'Total Paid Subscriptions'}
            amount={profile?.count?.paid || 0}
            Icon={PersonsIcon}
          />

          <StatsCard
            title={'Total Commissions Earned'}
            amount={moneyFormat(profile?.total?.earned, currency)}
            Icon={MoneyIcon}
          />
          <StatsCard
            title={'Total Commissions Paid'}
            amount={moneyFormat(profile?.total?.paid, currency)}
            Icon={MoneyCashIcon}
          />
          <StatsCard
            title={'Available Balance'}
            amount={moneyFormat(getAvailableBalance(profile), currency)}
            Icon={MoneyIconBlue}
          />
        </div>
        <div>
          <div className="fw-medium fs-5 text-secondary mb-3">
            Referrer Earnings
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-12 col-md-4">
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={keyword}
                  onChange={(e) => handleChange(e, 'keyword')}
                  placeholder="Enter search term here..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>
            <div className="col-12 col-md-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="outlined"
                  size="small"
                  fullWidth
                  autoOk={true}
                  label="Date From"
                  format="dd/MM/yyyy"
                  placeholder="mm/dd/yyyy"
                  value={dateFrom}
                  onChange={(e) => handleChange(e, 'dateFrom')}
                  style={{ backgroundColor: '#fff' }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-12 col-md-2">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="outlined"
                  size="small"
                  fullWidth
                  autoOk={true}
                  label="Date To"
                  format="dd/MM/yyyy"
                  placeholder="mm/dd/yyyy"
                  value={dateTo}
                  onChange={(e) => handleChange(e, 'dateTo')}
                  style={{ backgroundColor: '#fff' }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="mb-4">
            <Tabs
              indicatorColor="primary"
              value={plan || 'ALL'}
              onChange={handleChangeTab}>
              {tabList?.map((item, index) => {
                let value = 0;
                return (
                  <Tab
                    key={index}
                    value={item?.value}
                    label={<TabLabel title={item?.label} label={value} />}
                  />
                );
              })}
            </Tabs>
          </div>
          {isLoading ? <PreloaderTable /> : null}
          {isLoading ? null : (
            <TableContainer component={Paper} className="border">
              <Table>
                <TableHead className="bg-light">
                  <TableRow>
                    <TableCell>Referral</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Subscribed Plan</TableCell>
                    <TableCell>Commission Earned</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {earnings?.data?.map((item) => {
                    const dateFormat = 'Do MMM, YYYY';
                    const referralMeta = item?.referralMeta;
                    const organizationId = referralMeta?.organizationId;
                    return (
                      <TableRow key={item?.id}>
                        <TableCell>
                          <Link
                            to={`${PATHNAMES.REFERRAL__BUSINESSES}/${item?.referralId}`}>
                            {referralMeta?.name}{' '}
                          </Link>
                        </TableCell>
                        <TableCell>{referralMeta?.email}</TableCell>
                        <TableCell>
                          {moment(item?.createdAt).format(dateFormat)}
                        </TableCell>
                        <TableCell>{item?.planMeta?.name}</TableCell>
                        <TableCell>
                          {moneyFormat(item?.amount, 'NGN')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {!isLoading && !earnings?.data?.length ? (
                <EmptyState
                  title={'Manage your referrals earnings here.'}
                  subtitle={'See details of invited businesses here.'}
                  link={PATHNAMES.REFERRAL__NEW}
                  btnContent={'Invite Business'}
                />
              ) : null}
            </TableContainer>
          )}

          <div className="paginationWrapper">
            <Pagination
              page={page}
              count={earnings?.totalPages}
              onChange={(_, v) => handleChange('page', v)}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  function handleChangeTab(_, value) {
    searchParams.set('plan', value);
    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  }

  function handleChange(e, name = '') {
    const value = name.includes('date')
      ? moment(e).format('YYYY-MM-DD')
      : name === 'page'
      ? e
      : e.target.value;
    searchParams.set(name, value);
    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
    if (name === 'page') scrollHandler();
  }
};

function TabLabel({ title, label }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <span>{title}</span>
      {/* <Chip label={label} /> */}
    </div>
  );
}

function StatsCard({ title, amount, Icon }) {
  return (
    <div className="col-12 col-md-3 mb-3">
      <div className="border rounded p-3 h-100 d-flex flex-column justify-content-between">
        <div className="d-flex gap-3 align-items-center mb-3">
          <div className="flex-grow-1 fs-sm">{title}</div>
          <div>
            <img src={Icon} alt={title} width={50} height={50} />
          </div>
        </div>
        <div className="fs-4 fw-medium">{amount}</div>
      </div>
    </div>
  );
}

function Status({ status }) {
  if (status === 'ACTIVE')
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-primary text-primary bg-opacity-25">
        Active
      </div>
    );
  if (status === 'EXPIRED')
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Expired
      </div>
    );
  if (status === 'INACTIVE')
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Inactive
      </div>
    );
  return null;
}

export default Earnings;
