import React from 'react';
import Header from './components/header';
import PersonsIcon from 'lib/assets/icons/referral__icon-3.svg';
import PersonsIconCheck from 'lib/assets/icons/referral__icon-4.svg';
import MoneyIcon from 'lib/assets/icons/referral__icon-2.svg';
import { moneyFormat } from 'lib/helpers/formatCurrency';
import useUserData from 'hooks/useUserData';
import {
  Button,
  Chip,
  FormControl,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
} from '@material-ui/core';
import { Add, SearchOutlined } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory, Link } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import { PATHNAMES } from 'lib/constants/pathnames';
import moment from 'moment';
import scrollHandler from 'utils/scrollHandler';
import referrerService from 'service/referrerService';
import EmptyState from 'modules/components/EmptyState/EmptyState';
import PreloaderTable from 'lib/components/Preloaders/Table';
import useFetch from 'hooks/useFetch';

const tabList = [
  { label: 'All', value: 'ALL' },
  { label: 'Simple Start', value: 'SIMPLE_START' },
  { label: 'Standard', value: 'STANDARD' },
  { label: 'Premium', value: 'PREMIUM' },
  { label: 'Free Trial', value: 'FREE_TRIAL' },
];

const STATUSES = [
  { label: 'All', value: 'ALL' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
];

const Dashboard = () => {
  const currency = 'NGN';
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const plan = searchParams.get('plan');
  const page = searchParams.get('page') || 1;
  const status = searchParams.get('status') || '';
  const dateFrom = searchParams.get('dateFrom') || null;
  const dateTo = searchParams.get('dateTo') || null;
  const query = { page, status, plan, limit: 10 };

  const referrerProfileQuery = useFetch({
    qKey: 'my-referrer-profile',
    requestFn: () => referrerService.profile(),
  });
  const referralsQuery = useFetch({
    qKey: 'referrals',
    keys: [...Object.values(query)],
    requestFn: () => referrerService.referrals(query),
  });

  const profile = referrerProfileQuery?.data?.data;
  const referrals = referralsQuery?.data;
  const isLoading = referralsQuery.isLoading;

  return (
    <React.Fragment>
      <Header title="Dashboard" subtitle="Manage all referrals on this page" />
      <div className="p-3">
        <div className="row mb-3">
          <StatsCard
            title={'Total Invited Businesses'}
            amount={profile?.count?.referral || 0}
            Icon={PersonsIcon}
          />
          <StatsCard
            title={'Total Number of Paid Subscriptions'}
            amount={profile?.count?.paid || 0}
            Icon={PersonsIconCheck}
          />
          <StatsCard
            title={'Total Commissions Earned'}
            amount={moneyFormat(profile?.total?.earned, currency)}
            Icon={MoneyIcon}
          />
        </div>
        <div>
          <div className="fw-medium fs-5 text-secondary mb-3">
            Recently invited businesses
          </div>
          <div className="row align-items-center mb-5">
            <div className="col-12 col-md-3 mb-3">
              <FormControl fullWidth size="small">
                <Select
                  variant="outlined"
                  displayEmpty
                  name="status"
                  className="bg-white"
                  onChange={(e) => handleChange(e, 'status')}
                  value={status}>
                  <MenuItem disabled value="" className="px-3">
                    Status
                  </MenuItem>
                  {STATUSES.map((item) => (
                    <MenuItem
                      key={item?.value}
                      value={item?.value}
                      className="px-3">
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="outlined"
                  size="small"
                  fullWidth
                  autoOk={true}
                  label="Date From"
                  format="dd/MM/yyyy"
                  placeholder="mm/dd/yyyy"
                  value={dateFrom}
                  onChange={(e) => handleChange(e, 'dateFrom')}
                  style={{ backgroundColor: '#fff' }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-12 col-md-3 mb-3">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="outlined"
                  size="small"
                  fullWidth
                  autoOk={true}
                  label="Date To"
                  format="dd/MM/yyyy"
                  placeholder="mm/dd/yyyy"
                  value={dateTo}
                  onChange={(e) => handleChange(e, 'dateTo')}
                  style={{ backgroundColor: '#fff' }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="col-12 col-md-3 mb-3">
              <div className="d-flex justify-content-end">
                <Button
                  endIcon={<Add />}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={PATHNAMES.REFERRAL__NEW}>
                  Invite Business
                </Button>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <Tabs
              indicatorColor="primary"
              value={plan || 'ALL'}
              className="border-bottom"
              onChange={handleChangeTab}>
              {tabList?.map((item, index) => {
                let value = 0;
                return (
                  <Tab
                    key={index}
                    value={item?.value}
                    label={<TabLabel title={item?.label} label={value} />}
                  />
                );
              })}
            </Tabs>
          </div>
          {isLoading ? <PreloaderTable /> : null}
          {isLoading ? null : (
            <TableContainer component={Paper} className="border">
              <Table>
                <TableHead className="bg-light">
                  <TableRow>
                    <TableCell>Business Name</TableCell>
                    <TableCell>Email Address</TableCell>
                    <TableCell>Joined Date</TableCell>
                    <TableCell>Plan</TableCell>
                    <TableCell>Commission</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {referrals?.data?.map((item) => {
                    const dateFormat = 'Do MMM, YYYY';
                    const earning = item?.earning;
                    const organizationMeta = item?.organizationMeta;
                    return (
                      <TableRow key={item?.id}>
                        <TableCell>
                          <Link
                            to={`${PATHNAMES.REFERRAL__BUSINESSES}/${item?.id}`}>
                            {organizationMeta?.name}
                          </Link>
                        </TableCell>
                        <TableCell>{organizationMeta?.email}</TableCell>
                        <TableCell>
                          {moment(organizationMeta?.createdAt).format(
                            dateFormat,
                          )}
                        </TableCell>
                        <TableCell>
                          {organizationMeta?.subscriptionPlanName}
                        </TableCell>
                        <TableCell>
                          {moneyFormat(earning?.total, 'NGN')}
                        </TableCell>
                        <TableCell>
                          <Status status={organizationMeta?.status} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {!isLoading && !referrals?.data?.length ? (
                <EmptyState
                  title={'Manage your referrals here.'}
                  subtitle={'See details of invited businesses here.'}
                  link={PATHNAMES.REFERRAL__NEW}
                  btnContent={'Invite Business'}
                />
              ) : null}
            </TableContainer>
          )}

          <div className="paginationWrapper">
            <Pagination
              page={page}
              count={referrals?.totalPages}
              onChange={(_, v) => handleChange('page', v)}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  function handleChangeTab(_, value) {
    searchParams.set('plan', value);
    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
  }

  function handleChange(e, name = '') {
    const value = name.includes('date')
      ? moment(e).format('YYYY-MM-DD')
      : name === 'page'
      ? e
      : e.target.value;
    searchParams.set(name, value);
    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
    if (name === 'page') scrollHandler();
  }
};

function TabLabel({ title, label }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <span>{title}</span>
      {/* <Chip label={label} /> */}
    </div>
  );
}

function StatsCard({ title, amount, Icon }) {
  return (
    <div className="col-12 col-md-4 mb-3">
      <div className="border rounded p-3 h-100">
        <div className="d-flex gap-3 align-items-center h-100">
          <div className="flex-grow-1 d-flex flex-column h-100">
            <div className="mb-3 flex-grow-1 fs-sm">{title}</div>
            <div className="fs-4 fw-medium">{amount}</div>
          </div>
          <div>
            <img src={Icon} alt={title} width={50} height={50} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Status({ status }) {
  if (status === 'ACTIVE')
    return (
      <div className="badge rounded-pill  fw-medium fs-sm bg-primary text-primary bg-opacity-25">
        Active
      </div>
    );
  if (status === 'EXPIRED')
    return (
      <div className="badge bg-danger text-danger rounded-pill fw-medium fs-sm bg-opacity-25">
        Expired
      </div>
    );
  if (status === 'INACTIVE')
    return (
      <div className="badge bg-warning text-warning rounded-pill fw-medium fs-sm bg-opacity-25">
        Inactive
      </div>
    );
  return null;
}

export default Dashboard;
