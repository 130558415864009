import React from 'react';
import { Link } from 'react-router-dom';
import BrandLogo from 'lib/assets/icons/brand_logo.png';
import { Avatar, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import useUserData from 'hooks/useUserData';
import {
  Business,
  ExitToApp,
  ExpandMore,
  List,
  LockOutlined,
  PersonOutline,
} from '@material-ui/icons';
import { PATHNAMES } from 'lib/constants/pathnames';

const Header = () => {
  const { user } = useUserData();
  const [isAccountMenu, setIsAccountMenu] = React.useState(null);

  return (
    <header>
      <nav className="d-flex p-3 justify-content-between align-items-center">
        <div>
          <Link to="/">
            <img src={BrandLogo} style={{ width: '7rem' }} alt="brand_logo" />
          </Link>
        </div>
        <div>
          <ul className="list-style-none p-0 m-0 d-flex align-items-center gap-3">
            <li>
              <div
                role="button"
                tabIndex={0}
                onClick={(e) => setIsAccountMenu(e.currentTarget)}
                className="d-flex align-items-center gap-2">
                <Avatar src="" className="bg-primary">
                  {user?.firstName?.charAt(0)}
                  {user?.lastName?.charAt(0)}
                </Avatar>
                <span className="d-none d-md-inline-block">
                  {user?.firstName} {user?.lastName}
                </span>
                <ExpandMore />
              </div>
              <Menu
                id="simple-menu"
                anchorEl={isAccountMenu}
                open={Boolean(isAccountMenu)}
                onClose={() => setIsAccountMenu(null)}>
                <MenuItem className="px-3" disabled>
                  Account Settings
                </MenuItem>
                <MenuItem
                  className="px-3"
                  component={Link}
                  to="/account/profile">
                  <ListItemIcon>
                    <PersonOutline />
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <MenuItem
                  className="px-3"
                  component={Link}
                  to="/account/invitations">
                  <ListItemIcon>
                    <List />
                  </ListItemIcon>
                  Invitations
                </MenuItem>
                <MenuItem
                  className="px-3"
                  component={Link}
                  to={PATHNAMES.ORGANIZATIONS}>
                  <ListItemIcon>
                    <Business />
                  </ListItemIcon>
                  Organizations
                </MenuItem>
                <MenuItem
                  className="px-3"
                  component={Link}
                  to="/account/password-and-security">
                  <ListItemIcon>
                    <LockOutlined />
                  </ListItemIcon>
                  Password and Security
                </MenuItem>
                <MenuItem
                  className="px-3 text-danger"
                  component={Link}
                  to={PATHNAMES.SIGN_OUT}>
                  <ListItemIcon>
                    <ExitToApp className="text-danger" />
                  </ListItemIcon>
                  Sign Out
                </MenuItem>
              </Menu>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
