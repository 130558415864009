import React from 'react';
import { Link } from 'react-router-dom';
import Notfound from 'lib/assets/images/404.jpg';
import { Button } from '@material-ui/core';

function Notfoundpage() {
  return (
    <React.Fragment>
      <div style={{ minHeight: '100vh' }}>
        <div className="d-flex h-100 justify-content-center align-items-center">
          <div className="d-flex flex-column align-items-center">
            <img src={Notfound} style={{ maxHeight: 300 }} />
            <div className="text-center">
              <h1>Page Not Found</h1>
              <p className="fs-5">
                The page you are trying to access is not available
              </p>
              <Button
                component={Link}
                to="/"
                variant="contained"
                color="primary">
                Return Home
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Notfoundpage;
