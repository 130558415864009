import moment from 'moment';
import httpService from './httpService';
import { User } from './userService';

const user = new User();
const OrgID = user.getOrgID();

class NotificationService {
  constructor() {
    this.url = `/user/cp/org/${OrgID}/notifications`;
    this.pushUrl = `/user/cp/org/${OrgID}/push-notification/fetch-one`;
  }
  get(page = 1) {
    return httpService.get(this.url + '?page=' + page);
  }
  getPushNotifications() {
    return httpService.get(`${this.url}/push`);
  }
  markAsRead() {
    const formData = {
      inAppNotifsLastViewedAt: moment().format('YYYY-MM-DD'),
      pushNotifsLastViewedAt: moment().format('YYYY-MM-DD'),
    };
    return httpService.put(this.url, formData);
  }
  initializePushNotifications(data) {
    return Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        const notification = new Notification(data?.title, {
          body: data?.message,
          icon: data?.imageURL,
          // tag: 'Welcome Message',
        });
        notification.onclick = (event) => {
          event.preventDefault();
          window.open(`/dashboard`);
        };
      }
    });
  }
}

export default new NotificationService();
