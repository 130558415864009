import http from './httpService';
import userService, { User } from './userService';

const user = new User();
const orgID = user.getOrgID();
const route = `/user/cp/org/${orgID}`;

export class Subscription {
  constructor() {
    this.organizationId = userService.getOrgID();
    this.route = `/user/cp/org/${orgID}`;
    this.endpoints = {
      freeTrial: `${this.route}/billing/free-trial-subscription-charge`,
      replaceCard: `/user/cp/org/${this.organizationId}/billing/generate-managed-subscription-update-link`,
      removeCard: `/user/cp/org/${this.organizationId}/billing/subscription-payment-card-removal`,
    };
  }
  getCurrentSub() {
    return http.get(`${route}/billing/subscription`);
  }
  cancelSub() {
    return http.put(`${route}/billing/subscription-cancelation`, { orgID });
  }

  async replaceCard() {
    return await http.post(this.endpoints.replaceCard, {});
  }
  async removeCard() {
    return await http.post(this.endpoints.removeCard, {});
  }
  getPlans() {
    return http.get(`${route}/billing/plans`);
  }
  invoices() {
    return http.get(`${route}/billing/invoices`);
  }
  getReceipts() {
    return http.get(`${route}/billing/payments`);
  }
  getSingleReceipt(id) {
    return http.get(`${route}/billing/payments/single?id=${id}`);
  }
  subscribe(planId, billingInterval) {
    const data = { planId, billingInterval };
    return http.post(`${route}/billing/subscription-payment`, data);
  }
  subscriptionPayment(data) {
    return http.post(`${route}/billing/subscription-payment`, data);
  }
  async freeTrial(planId) {
    const data = { planId };
    return await http.post(this.endpoints.freeTrial, data);
  }
  // freeTrial(planId) {
  //   const data = { planId };
  //   return http.post(`${route}/billing/free-trial-subscription`, data);
  // }
  formatPlans(data = []) {
    const formatedPlans = data?.map((item) => {
      if (item?.name === 'Premium') {
        return {
          index: 3,
          ...item,
          heading: 'Suitable for Medium Scale Enterprises',
          prevPrice: {
            MONTHLY: 15000,
            QUARTERLY: 35000,
            BIANNUALLY: 65000,
            ANNUALLY: 100000,
          },
          content: [
            'Everything in Standard plus:',
            'Convert multiple currencies',
            'Tax calendar',
            'Manage fixed assets',
            'Create and manage budget',
            'Staff performance evaluation reports',
            'Schedule staff meetings',
            'Manage employee transfer',
            'Manage employee suspension',
            'Manage employee promotion',
            'Create internal memos',
            'Supports 1 super admin plus unlimited sub-admin users',
          ],
        };
      } else if (item?.name === 'Standard') {
        return {
          index: 2,
          ...item,
          heading: 'Suitable for Small Scale Enterprises',
          prevPrice: {
            MONTHLY: 8500,
            QUARTERLY: 18500,
            BIANNUALLY: 33500,
            ANNUALLY: 50000,
          },
          content: [
            'Everything in Simple Start plus:',
            'Invite and manage 50 employees',
            'Query employees',
            'Run payroll',
            'Manage employee performance evaluation',
            'Create departments and Branches',
            'Customer and advanced financial reporting',
            'Switch between invoice and quotations template',
            'Create Quotation',
            'Support one admin user plus 5 sub-admin users',
          ],
        };
      } else if (item?.name === 'Simple Start') {
        return {
          index: 1,
          ...item,
          heading: 'Suitable for Micro and Small Scale Businesses',
          prevPrice: {
            MONTHLY: 5000,
            QUARTERLY: 9000,
            BIANNUALLY: 15000,
            ANNUALLY: 20000,
          },
          content: [
            'Track income & expenses',
            'Send custom invoices',
            'Switch between invoice and quotations templates',
            'Reconcile bank transactions',
            'Manage cash flow',
            'Manage inventory',
            'Support one admin user only',
            'Invite and manage employees',
            'Manage employee leave',
            'Manage employee pay slip and payroll',
            'Create 5 departments ',
            'Upload signature/sign directly',
            'Receive payment from customers',
          ],
        };
      }
    });
    return formatedPlans?.sort((a, b) => a?.index - b?.index);
  }
}

export default new Subscription();
