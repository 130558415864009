import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Header from './components/header';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useUserData from 'hooks/useUserData';
import { Pagination } from '@material-ui/lab';
import PersonsIconCheck from 'lib/assets/icons/referral__icon-4.svg';
import MoneyIcon from 'lib/assets/icons/referral__icon-2.svg';
import { moneyFormat } from 'lib/helpers/formatCurrency';
import moment from 'moment';
import referrerService from 'service/referrerService';
import httpService from 'service/httpService';
import EmptyState from 'modules/components/EmptyState/EmptyState';
import PreloaderTable from 'lib/components/Preloaders/Table';

const BusinessInfo = () => {
  const { id } = useParams();
  const history = useHistory();
  const {} = useUserData();
  const currency = 'NGN';

  const searchParams = new URLSearchParams(window.location.search);
  const plan = searchParams.get('plan');
  const page = searchParams.get('page') || 1;
  const status = searchParams.get('status');
  const [isLoading, setIsLoading] = React.useState(false);
  const [referrer, setReferrer] = React.useState(null);
  const [earnings, setEarnings] = React.useState({ data: [] });

  const query = { page, status, plan, id };

  React.useEffect(() => {
    getReferral();
  }, []);
  React.useEffect(() => {
    getReferralEarnings();
  }, [page, status, plan]);

  return (
    <React.Fragment>
      <Header
        title="Invited Businesses"
        subtitle="Get insights into your earnings from this business"
      />
      <div className="p-3">
        <div className="mb-4">
          <IconButton
            onClick={() => history.goBack()}
            style={{ background: '#EEF5FC' }}>
            <ArrowBack />
          </IconButton>
        </div>
        <div className="mb-5">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="border p-3 py-2 rounded h-100">
                <div className="fs-5 fw-medium mb-2">
                  {referrer?.organizationMeta?.name}
                </div>
                <div className="fs-sm mb-2">
                  Phone Number:{' '}
                  <span className="fw-medium">
                    {referrer?.organizationMeta?.phone?.startsWith('+') ? (
                      <span>{referrer?.organizationMeta?.phone}</span>
                    ) : (
                      <span
                        className={
                          referrer?.organizationMeta?.phone ? '' : 'd-none'
                        }>
                        +{referrer?.organizationMeta?.phone}
                      </span>
                    )}
                  </span>
                </div>
                <div className="fs-sm mb-2 text-truncate">
                  Email:{' '}
                  <span className="fw-medium text-decoration-underline text-primary">
                    {referrer?.organizationMeta?.email}
                  </span>
                </div>
                <div className="fs-sm mb-2">
                  Join Date:{' '}
                  <span className="fw-medium">
                    {moment(referrer?.createdAt).format('Do MMM, YYYY')}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="h-100">
                <div className="border p-3 py-2 rounded mb-3">
                  <StatsCard
                    title={'Total Times Subscribed'}
                    Icon={PersonsIconCheck}
                    amount={referrer?.count?.subscription}
                  />
                </div>
                <div className="border p-3 rounded">
                  <StatsCard
                    title={'Total Commission Earned'}
                    Icon={MoneyIcon}
                    amount={moneyFormat(referrer?.earning?.total, currency)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fw-medium fs-5 text-secondary mb-3">
          Recent subscriptions
        </div>
        {isLoading ? <PreloaderTable /> : null}
        {isLoading ? null : (
          <TableContainer component={Paper} className="border">
            <Table>
              <TableHead className="bg-light">
                <TableRow>
                  <TableCell>Plan</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Commission Earned</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {earnings?.data?.map((item) => {
                  const referralMeta = item?.referralMeta;
                  return (
                    <TableRow key={item?.id}>
                      <TableCell>{item?.planMeta?.name}</TableCell>
                      <TableCell>
                        {item?.referralMeta?.subscriptionBillingInterval}
                      </TableCell>
                      <TableCell>
                        {moment(referralMeta?.subscriptionStartAt).format(
                          'Do MMM, YYYY',
                        )}
                      </TableCell>
                      <TableCell>
                        {moneyFormat(
                          calculateAmountBeforeCommission(
                            item?.amount,
                            item?.commission?.amount,
                          ),
                          'NGN',
                        )}
                      </TableCell>
                      <TableCell>{moneyFormat(item?.amount, 'NGN')}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {!isLoading && !earnings?.data?.length ? (
              <EmptyState title={''} subtitle={'No subscriptions available'} />
            ) : null}
          </TableContainer>
        )}

        <div className="paginationWrapper">
          <Pagination
            page={page}
            count={earnings?.totalPages}
            onChange={(_, v) => handleChange('page', v)}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </React.Fragment>
  );

  function calculateAmountBeforeCommission(commission, percentage) {
    return commission / (percentage / 100);
  }

  async function getReferral() {
    try {
      const { data } = await referrerService.getReferral({ id });
      setReferrer(data?.data);
    } catch (error) {
      httpService.errorHandler(error);
    }
  }

  async function getReferralEarnings() {
    try {
      setIsLoading(true);
      const { data } = await referrerService.referralEarnings(query);
      setEarnings(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      httpService.errorHandler(error);
    }
  }

  function handleChange(e, name = '') {
    const value = name.includes('date')
      ? moment(e).format('YYYY-MM-DD')
      : name === 'page'
      ? e
      : e.target.value;
    searchParams.set(name, value);
    history.replace({
      pathname: window.location.pathname,
      search: searchParams.toString(),
    });
    if (name === 'page') scrollHandler();
  }
};

function StatsCard({ title, amount, Icon }) {
  return (
    <div className="d-flex gap-3 align-items-center">
      <div className="flex-grow-1 d-flex flex-column">
        <div className="mb-2 flex-grow-1 fs-sm">{title}</div>
        <div className="fw-medium">{amount}</div>
      </div>
      <div>
        <img src={Icon} alt={title} width={40} height={40} />
      </div>
    </div>
  );
}

export default BusinessInfo;
