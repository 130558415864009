const fetchProducts = 'FETCH_PRODUCTS';
const fetchServices = 'FETCH_SERVICES';
const fetchInvoices = 'FETCH_INVOICES';
const refreshInvoices = 'REFRESH_INVOICES';
const deleteInvoice = 'DELETE_INVOICE';
const fetchCreditNotes = 'FETCH_CREDIT_NOTES';
const fetchSingleOrg = 'FETCH_SINGLE_ORG';
const fetchOrganizations = 'FETCH_ORGANIZATIONS';
const refreshOrganizations = 'REFRESH_ORGANIZATIONS';
const fetchInvitations = 'FETCH_INVITATIONS';
const refreshInvitations = 'REFRESH_INVITATIONS';
const acceptInvitation = 'ACCEPT_INVITATION';
const rejectInvitation = 'REJECT_INVITATION';
const fetchPlans = 'FETCH_PLANS';
const fetchCurrentPlan = 'FETCH_CURRENT_PLAN';
const fetchPlanReceipts = 'FETCH_PLAN_RECEIPTS';
const fetchEmployees = 'FETCH_EMPLOYEES';
const fetchSingleEmployee = 'FETCH_SINGLE_EMPLOYEE';
const fetchDepartments = 'FETCH_DEPARTMENTS';
const fetchBranches = 'FETCH_BRANCHES';

export {
  fetchProducts,
  fetchServices,
  refreshInvoices,
  fetchInvoices,
  fetchCreditNotes,
  fetchSingleOrg,
  fetchOrganizations,
  fetchInvitations,
  acceptInvitation,
  rejectInvitation,
  deleteInvoice,
  refreshOrganizations,
  refreshInvitations,
  fetchPlans,
  fetchCurrentPlan,
  fetchPlanReceipts,
  fetchEmployees,
  fetchSingleEmployee,
  fetchDepartments,
  fetchBranches,
};
