import { UserContext } from 'context/user/user';
import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function Home() {
  const history = useHistory();
  const { accessToken } = React.useContext(UserContext);
  const searchParams = new URLSearchParams(window.location.search);
  const referralCode = searchParams.get('ref');
  const redirect = searchParams.get('redirect');

  if (accessToken) window.location.replace(PATHNAMES.ORGANIZATIONS);
  else if (redirect === 'invitation') {
    window.location.replace(PATHNAMES.NEW_INVITES);
  } else if (referralCode) {
    window.location.replace(`${PATHNAMES.SIGN_UP}?ref=${referralCode}`);
  } else window.location.replace(PATHNAMES.SIGN_IN);

  return null;
}
