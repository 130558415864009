const locales = {
  NGN: 'en-NG',
  USD: 'en-US',
  GBP: 'en-GB',
  EUR: 'en-EU',
};
const currencyCodes = {
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  AMD: 'AMD',
  ANG: 'ANG',
  AOA: 'AOA',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BDT: 'BDT',
  BGN: 'BGN',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BOV: 'BOV',
  BRL: 'BRL',
  BSD: 'BSD',
  BTN: 'BTN',
  BWP: 'BWP',
  BYR: 'BYR',
  BZD: 'BZD',
  CAD: 'CAD',
  CDF: 'CDF',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  COU: 'COU',
  CRC: 'CRC',
  CUC: 'CUC',
  CUP: 'CUP',
  CVE: 'CVE',
  CZK: 'CZK',
  DJF: 'DJF',
  DKK: 'DKK',
  DOP: 'DOP',
  DZD: 'DZD',
  EGP: 'EGP',
  ERN: 'ERN',
  ETB: 'ETB',
  EUR: 'EUR',
  FJD: 'FJD',
  FKP: 'FKP',
  GBP: 'GBP',
  GEL: 'GEL',
  GHS: 'GHS',
  GIP: 'GIP',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HTG: 'HTG',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  IQD: 'IQD',
  IRR: 'IRR',
  ISK: 'ISK',
  JMD: 'JMD',
  JOD: 'JOD',
  JPY: 'JPY',
  KES: 'KES',
  KGS: 'KGS',
  KHR: 'KHR',
  KMF: 'KMF',
  KPW: 'KPW',
  KRW: 'KRW',
  KWD: 'KWD',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  LSL: 'LSL',
  LTL: 'LTL',
  LVL: 'LVL',
  LYD: 'LYD',
  MAD: 'MAD',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: 'MKD',
  MMK: 'MMK',
  MNT: 'MNT',
  MOP: 'MOP',
  MRO: 'MRO',
  MUR: 'MUR',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: 'MXN',
  MXV: 'MXV',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PGK: 'PGK',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  RWF: 'RWF',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SDG: 'SDG',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SLL: 'SLL',
  SOS: 'SOS',
  SRD: 'SRD',
  SSP: 'SSP',
  STD: 'STD',
  SYP: 'SYP',
  SZL: 'SZL',
  THB: 'THB',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: 'TND',
  TOP: 'TOP',
  TRY: 'TRY',
  TTD: 'TTD',
  TWD: 'TWD',
  TZS: 'TZS',
  UAH: 'UAH',
  UGX: 'UGX',
  USD: 'USD',
  USN: 'USN',
  USS: 'USS',
  UYI: 'UYI',
  UYU: 'UYU',
  UZS: 'UZS',
  VEF: 'VEF',
  VND: 'VND',
  VUV: 'VUV',
  WST: 'WST',
  XAF: 'XAF',
  XAG: 'XAG',
  XAU: 'XAU',
  XBA: 'XBA',
  XBB: 'XBB',
  XBC: 'XBC',
  XBD: 'XBD',
  XCD: 'XCD',
  XDR: 'XDR',
  XFU: 'XFU',
  XOF: 'XOF',
  XPD: 'XPD',
  XPF: 'XPF',
  XPT: 'XPT',
  XTS: 'XTS',
  XXX: 'XXX',
  YER: 'YER',
  ZAR: 'ZAR',
  ZMW: 'ZMW',
};

export const moneySymbol = getCurrencySymbol();

export const moneyFormat = (amount, currency) => {
  const ORGANIZATION = JSON.parse(localStorage.getItem('ORGANIZATION'));
  let defaultCurrency = ORGANIZATION?.currency;
  if (!!currency) defaultCurrency = currency;

  try {
    let number = Number(amount);
    let locale = locales[defaultCurrency];
    if (!locale) locale = 'en-GB';
    const isExist = currencyCodes[defaultCurrency];
    if (!isExist) defaultCurrency = undefined;

    if (isNaN(number)) number = 0;
    if (!defaultCurrency)
      return new Intl.NumberFormat({
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: defaultCurrency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  } catch (error) {
    console.error('Currency format error:', error);
  }
};

export function getCurrencySymbol(currency) {
  const ORGANIZATION = JSON.parse(localStorage.getItem('ORGANIZATION'));
  let defaultCurrency = ORGANIZATION?.currency;
  if (!!currency) defaultCurrency = currency;
  if (!defaultCurrency) return '';

  try {
    let locale = locales[defaultCurrency] || 'en-GB';
    const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: defaultCurrency,
    });
    const parts = formatter.formatToParts(1);
    for (const part of parts) {
      if (part.type === 'currency') {
        return part.value;
      }
    }
    return '';
  } catch (error) {
    console.error('Currency symbol retrieval error:', error);
    return null;
  }
}
