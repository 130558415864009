import React from 'react';
import { OrganizationContext } from 'context/organization/organization';
import { UserContext } from 'context/user/user';
import { useContext } from 'react';
import Cookies from 'js-cookie';

const accessToken = Cookies.get('_access_auth');

function useUserData() {
  const { user, setRefetch } = React.useContext(UserContext);
  const { admin, organization } = useContext(OrganizationContext);

  return {
    user,
    admin,
    organization,
    isAuth: !!accessToken,
    currency: organization?.currency,
    resetUserData: () => setRefetch((prev) => !prev),
    authAdministrator: admin,
    excludedPermissions: admin?.excludedPermissions || [],
  };
}

export default useUserData;
