import React from 'react';
import { Link } from 'react-router-dom';
import BrandLogo from 'lib/assets/icons/brand_logo.png';
import {
  Avatar,
  Drawer,
  IconButton,
  ListItemIcon,
  Menu,
  List,
  MenuItem,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import useUserData from 'hooks/useUserData';
import {
  ExitToApp,
  ExpandMore,
  LockOutlined,
  PersonOutline,
  DashboardOutlined,
  Business,
} from '@material-ui/icons';
import ListIcon from '@material-ui/icons/List';
import { PATHNAMES } from 'lib/constants/pathnames';
import MenuIcon from '@material-ui/icons/Menu';
import InvoiceIcon from '../../../components/Icons/invoice';

const Header = ({ title, subtitle }) => {
  const { user } = useUserData();
  const pathname = window.location.pathname;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isAccountMenu, setIsAccountMenu] = React.useState(null);

  const NAVIGATION_LINKS = [
    {
      id: 1,
      name: 'Dashboard',
      link: PATHNAMES.REFERRAL__DASHBOARD,
      Icon: DashboardOutlined,
    },
    {
      id: 2,
      name: 'Earnings',
      link: PATHNAMES.REFERRAL__EARNINGS,
      Icon: InvoiceIcon,
    },
  ];

  return (
    <React.Fragment>
      <header>
        <nav className="d-flex p-3 justify-content-between">
          <div className="d-flex align-items-center gap-4">
            <IconButton onClick={toggleDrawer} className="d-lg-none">
              <MenuIcon />
            </IconButton>
            <div>
              <div className="fw-semibold fs-4">{title}</div>
              <div className="d-none d-lg-block">{subtitle}</div>
            </div>
          </div>
          <div>
            <ul className="list-style-none p-0 m-0 d-flex align-items-center gap-3">
              <li>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={(e) => setIsAccountMenu(e.currentTarget)}
                  className="d-flex align-items-center gap-2">
                  <Avatar src="" className="bg-primary">
                    {user?.firstName?.charAt(0)}
                    {user?.lastName?.charAt(0)}
                  </Avatar>
                  <span className="d-none d-md-inline-block">
                    {user?.firstName} {user?.lastName}
                  </span>
                  <ExpandMore />
                </div>
                <Menu
                  id="simple-menu"
                  anchorEl={isAccountMenu}
                  open={Boolean(isAccountMenu)}
                  onClose={() => setIsAccountMenu(null)}>
                  <MenuItem className="px-3" disabled>
                    Account Settings
                  </MenuItem>
                  <MenuItem
                    className="px-3"
                    component={Link}
                    to="/account/profile">
                    <ListItemIcon>
                      <PersonOutline />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                  <MenuItem
                    className="px-3"
                    component={Link}
                    to="/account/invitations">
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    Invitations
                  </MenuItem>
                  <MenuItem
                    className="px-3"
                    component={Link}
                    to={PATHNAMES.ORGANIZATIONS}>
                    <ListItemIcon>
                      <Business />
                    </ListItemIcon>
                    Organizations
                  </MenuItem>
                  <MenuItem
                    className="px-3"
                    component={Link}
                    to="/account/password-and-security">
                    <ListItemIcon>
                      <LockOutlined />
                    </ListItemIcon>
                    Password and Security
                  </MenuItem>
                  <MenuItem
                    className="px-3 text-danger"
                    component={Link}
                    to={PATHNAMES.SIGN_OUT}>
                    <ListItemIcon>
                      <ExitToApp className="text-danger" />
                    </ListItemIcon>
                    Sign Out
                  </MenuItem>
                </Menu>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <Drawer anchor={'left'} open={isOpen} onClose={() => setIsOpen(false)}>
        <div style={{ width: 240 }} className="h-100">
          <div className="d-flex justify-content-center py-4 border-bottom mb-4">
            <Link to="/">
              <img
                src={BrandLogo}
                alt="brand"
                style={{ width: '7rem', height: '3rem' }}
              />
            </Link>
          </div>
          <div role="presentation">
            <List>
              {NAVIGATION_LINKS.map(({ Icon, link, name }) => (
                <ListItem
                  button
                  component={Link}
                  to={link}
                  key={link}
                  onClick={() => setIsOpen(false)}
                  className={pathname.includes(link) ? 'text-primary' : ''}>
                  <ListItemIcon>
                    <Icon
                      className={pathname.includes(link) ? 'text-primary' : ''}
                    />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );

  function toggleDrawer(event) {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsOpen(true);
  }
};

export default Header;
