import React from 'react';

const HRContext = React.createContext({});

const HRContextProvider = ({ children }) => {
  const [employees, setEmployees] = React.useState({ data: [] });
  const [employee, setEmployee] = React.useState(null);
  const [leaves, setLeaves] = React.useState({
    pending: {},
    approved: {},
    rejected: {},
    started: {},
    cancelled: {},
    ended: {},
  });
  const [query, setQuery] = React.useState({
    pending: {},
    closed: {},
  });
  const [suspension, setSuspension] = React.useState({
    pending: {},
    active: {},
    ended: {},
  });
  const [payrolls, setPayrolls] = React.useState({ data: [] });
  const [payslips, setPayslips] = React.useState({ data: [] });
  const [evaluations, setEvaluations] = React.useState({ data: [] });
  const [memos, setMemos] = React.useState({ data: [] });
  const [meetings, setMeetings] = React.useState({ data: [] });
  const [transfers, setTransfers] = React.useState({ data: [] });
  const [terminations, setTerminations] = React.useState({ data: [] });
  const [retirements, setRetirements] = React.useState({ data: [] });
  const [promotions, setPromotions] = React.useState({ data: [] });

  return (
    <HRContext.Provider
      value={{
        employees,
        setEmployees,
        employee,
        setEmployee,
        leaves,
        setLeaves,
        payrolls,
        setPayrolls,
        payslips,
        setPayslips,
        evaluations,
        setEvaluations,
        memos,
        setMemos,
        meetings,
        setMeetings,
        query,
        setQuery,
        transfers,
        setTransfers,
        terminations,
        setTerminations,
        retirements,
        setRetirements,
        suspension,
        setSuspension,
        promotions,
        setPromotions,
      }}>
      {children}
    </HRContext.Provider>
  );
};

export { HRContext, HRContextProvider };
