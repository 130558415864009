const Config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  okraUrl: process.env.REACT_APP_OKRA_BASE_URL,
  okraToken: process.env.REACT_APP_OKRA_TOKEN,
  okraKey: process.env.REACT_APP_OKRA_KEY,
  okraEnv: process.env.REACT_APP_OKRA_ENV,
  // Firebase Keys
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_APP_ID || '',
  measurementId: process.env.REACT_APP_FIREBASE_MESSUREMENT_ID || '',
  // encryption
  encryption: process.env.REACT_APP_ENCRYPTION_SECRET,
  IDLE_DURATION: process.env.REACT_APP_IDLE_DURATION || 1000 * 60 * 15,
};
export default Config;
