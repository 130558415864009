import { Button, Paper } from '@material-ui/core';
import Menu from 'lib/components/Menu/Menu';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as EmailSent } from 'lib/assets/images/emailSent.svg';
import { PATHNAMES } from 'lib/constants/pathnames';

const ForgotPasswordSuccess = () => {
  return (
    <div className="container-xxl">
      <Menu />
      <Paper
        className="p-3 py-5 border mx-auto my-4 rounded-4"
        style={{ maxWidth: 600 }}>
        <div style={{ maxWidth: 350 }} className="mx-auto">
          <div className="d-flex justify-content-center">
            <EmailSent />
          </div>
          <div className="my-4 mb-5 fs-sm text-center px-md-4">
            We have sent an email to your inbox with directions on how to reset
            your password.
          </div>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            component={Link}
            to={PATHNAMES.SIGN_IN}>
            Back to Login
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default ForgotPasswordSuccess;
