import React from 'react';
import Header from '../components/header';
import { Button, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import OrganizationImg from 'lib/assets/images/organization_1.png';
import { PATHNAMES } from 'lib/constants/pathnames';
import useUserData from 'hooks/useUserData';
import { useHistory } from 'react-router-dom';
import httpService from 'service/httpService';
import { KeyboardArrowRight } from '@material-ui/icons';

const OPTIONS = {
  REFERRAL: 'REFERRAL',
  ORGANIZATION: 'ORGANIZATION',
};

const LINK = `${location?.pathname}${location.search}`;
const REDIRECT_URL = `${PATHNAMES.ACCOUNT_VERIFICATION}?type=new&redirect=${LINK}`;
const FeatureSelection = () => {
  const { user } = useUserData();
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const REFERRER_CODE = searchParams.get('referrerCode');

  React.useEffect(() => {
    if (!user) return;
    if (!user?.isVerified) {
      httpService.toast.error('Account verification required...');
      history.replace(REDIRECT_URL);
    }
  }, [user]);

  let CREATE_ORGANIZATION_LINK = PATHNAMES.CREATE_ORGANIZATIONS;
  if (REFERRER_CODE) {
    CREATE_ORGANIZATION_LINK = `${CREATE_ORGANIZATION_LINK}?referrerCode=${REFERRER_CODE}`;
  }

  return (
    <div className="container-xxl">
      <Header />
      <div style={{ minHeight: '90vh' }} className="d-flex align-items-center">
        <Paper
          className="p-3 px-5 py-5 border mx-auto my-5 rounded-4"
          style={{ maxWidth: 800, width: '100%' }}>
          <div className="mb-4 mx-auto" style={{ maxWidth: 400 }}>
            <div className="fs-3 text-center fw-medium">Welcome to Jureb</div>
            <div className="text-muted text-center fs-sm">
              You can get started by creating your business profile or by
              joining our referral program
            </div>
          </div>
          <Link
            className="bg-primary rounded p-4 my-5 d-block"
            to={CREATE_ORGANIZATION_LINK}>
            <div className="d-flex gap-3 align-items-center">
              <div>
                <img src={OrganizationImg} alt={OPTIONS.ORGANIZATION} />
              </div>
              <div className="fs-5 fw-medium flex-grow-1 text-white">
                Create a Business Profile
              </div>
              <div>
                <KeyboardArrowRight className="fs-4 text-white" />
              </div>
            </div>
          </Link>
          <Link
            className="text-decoration-underline text-primary text-center d-block"
            to={PATHNAMES.REFERRAL__DASHBOARD}>
            Join the Referral Program
          </Link>
        </Paper>
      </div>
    </div>
  );
};

export default FeatureSelection;
