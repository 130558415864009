function random(number) {
  return Math.floor(Math.random() * number);
}
const colors = [
  '#FF0000',
  '#0000FF',
  '#006400',
  '#FF6600',
  '#000000',

  '#964B00',
  '#808080',
  '#FFC0CB',
  '#808000',
  '#8F00FF',
  '	#36454F',
  '	#CD7F32',
  '	#008080',
  '#000080',
  '#007FFF',
  '#3EB489',
  '	#FFBF00',
  '#FF0000',
  '#0000FF',
  '#006400',
  '#FF6600',
  '#000000',

  '#964B00',
  '#808080',
  '#FFC0CB',
  '#808000',
  '#8F00FF',
  '	#36454F',
  '	#CD7F32',
  '	#008080',
  '#000080',
  '#007FFF',
  '#3EB489',
  '	#FFBF00',
];
function getAlphabethColor(letter = '') {
  let color = '';
  if (letter.toLowerCase() === 'a') {
    color = colors[0];
  } else if (letter.toLowerCase() === 'b') {
    color = colors[1];
  } else if (letter.toLowerCase() === 'c') {
    color = colors[2];
  } else if (letter.toLowerCase() === 'd') {
    color = colors[3];
  } else if (letter.toLowerCase() === 'e') {
    color = colors[4];
  } else if (letter.toLowerCase() === 'f') {
    color = colors[5];
  } else if (letter.toLowerCase() === 'g') {
    color = colors[6];
  } else if (letter.toLowerCase() === 'h') {
    color = colors[7];
  } else if (letter.toLowerCase() === 'i') {
    color = colors[8];
  } else if (letter.toLowerCase() === 'j') {
    color = colors[9];
  } else if (letter.toLowerCase() === 'k') {
    color = colors[10];
  } else if (letter.toLowerCase() === 'l') {
    color = colors[11];
  } else if (letter.toLowerCase() === 'm') {
    color = colors[12];
  } else if (letter.toLowerCase() === 'n') {
    color = colors[13];
  } else if (letter.toLowerCase() === 'o') {
    color = colors[14];
  } else if (letter.toLowerCase() === 'p') {
    color = colors[15];
  } else if (letter.toLowerCase() === 'q') {
    color = colors[16];
  } else if (letter.toLowerCase() === 'r') {
    color = colors[17];
  } else if (letter.toLowerCase() === 's') {
    color = colors[18];
  } else if (letter.toLowerCase() === 't') {
    color = colors[19];
  } else if (letter.toLowerCase() === 'u') {
    color = colors[20];
  } else if (letter.toLowerCase() === 'v') {
    color = colors[21];
  } else if (letter.toLowerCase() === 'w') {
    color = colors[21];
  } else if (letter.toLowerCase() === 'x') {
    color = colors[23];
  } else if (letter.toLowerCase() === 'y') {
    color = colors[24];
  } else if (letter.toLowerCase() === 'z') {
    color = colors[25];
  }

  return color;
}

export default getAlphabethColor;
