import { Avatar, IconButton, Popover } from '@material-ui/core';
import getAlphabethColor from 'lib/helpers/colorSelect';
import React from 'react';
import Organization from './Organization';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import httpService from 'service/httpService';
import userService from 'service/userService';
import { PATHNAMES } from 'lib/constants/pathnames';
import { ArrowForward } from '@material-ui/icons';
import BTNLoader from 'lib/components/Preloaders/Btn';

const OrganizationPopup = ({ user, open, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [organizations, setOrganizations] = React.useState([]);

  React.useEffect(() => {
    getOrganizations();
  }, []);
  return (
    <Popover
      id={open !== null ? 'simple-popover' : undefined}
      open={Boolean(open)}
      anchorEl={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: 350,
        },
      }}>
      <div className="org_menu">
        <div className="org_menu_wrapper">
          <header className="org_menu_header">
            <div className="wrapper">
              <Avatar
                src={user?.logoUploadMeta?.url}
                alt={user?.firstName}
                style={{
                  backgroundColor: getAlphabethColor(
                    user?.firstName?.charAt(0) || '',
                  ),
                }}>
                {user?.firstName?.charAt(0)}
                {user?.lastName?.charAt(0)}
              </Avatar>
              <div style={{ marginLeft: 5 }}>
                <div>
                  {user?.firstName} {user?.lastName}
                </div>
                <div style={{ fontSize: 10 }}>{user?.email}</div>
              </div>
            </div>
            <div onClick={onClose}>
              <IconButton>
                <CloseIcon style={{ color: '#666' }} />
              </IconButton>
            </div>
          </header>
          <div className="hr_line"></div>
          <div className="org_menu_main">
            <section className="org_menu_section">
              <span className="header_span">Orgnaizations</span>
              {isLoading ? (
                <div className="py-4 d-flex justify-content-center">
                  <BTNLoader />
                </div>
              ) : null}
              <ul>
                {organizations?.slice(0, 4)?.map((item, i) => (
                  <Organization
                    key={i}
                    item={item}
                    handleSelect={handleSelect}
                  />
                ))}
              </ul>

              <Link
                to={PATHNAMES.ORGANIZATIONS}
                className="text-primary d-block mt-3">
                View All <ArrowForward className="text-primary" />
              </Link>

              <Link
                to={PATHNAMES.CREATE_ORGANIZATIONS}
                className="text-primary d-block mt-4 d-flex align-items-center">
                <ControlPointIcon className="text-primary me-2" />
                Create a new Business{' '}
              </Link>
            </section>

            <div className="hr_line"></div>
            <section className="org_menu_section">
              <div className="user_email">
                You are signed in as <span>{user?.email}</span>
              </div>
              <div style={{ marginTop: '1.5rem' }}>
                <Link
                  className="create_new"
                  onClick={onClose}
                  to="/dashboard/account/profile">
                  <PersonOutlineIcon
                    style={{
                      color: '#1f53d7',
                      fontSize: '1.1rem',
                      marginRight: '5px',
                    }}
                  />{' '}
                  Manage Profile
                </Link>
              </div>
            </section>
          </div>
          <div className="hr_line"></div>
          <footer>
            <a href="https://www.jureb.com/terms/" target="_blank">
              Terms and Privacy
            </a>
          </footer>
        </div>
      </div>
    </Popover>
  );

  function handleSelect(data) {
    localStorage.setItem('organizationId', data?.id);
    localStorage.setItem('ORGANIZATION', JSON.stringify(data));
    location.replace('/dashboard');
  }

  async function getOrganizations() {
    try {
      setIsLoading(true);
      const { data } = await userService.getOrgs();
      setOrganizations(data?.data);
      setIsLoading(false);
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }
};

export default OrganizationPopup;
