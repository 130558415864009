import React from 'react';
import {
  CircularProgress,
  Dialog,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { classes } from 'istanbul-lib-coverage';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: '#1f53d7',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
  center: {
    color: '#1f53d7',
    animationDuration: '550ms',
  },
  circle: {
    strokeLinecap: 'round',
  },
  fallback: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffffe6',
    zIndex: 2000,
  },
  dashLoader: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#ffffffe6',
    zIndex: 2000,
  },
  full: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 2000,
  },
  section: {
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  side: {
    position: 'fixed',
    left: 260,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    overflow: 'hidden',
    '@media(max-width: 960px)': {
      left: 0,
    },
  },
}));

export const FullLoader = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.full}>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </div>
      {/* <Lottie options={defaultOptions} height={100} width={100} /> */}
    </div>
    // </Dialog>
  );
};
export const FallbackUI = () => {
  return (
    <div className="p-3 h-100 overflow-hidden">
      <div className="flex-grow-1">
        <div className="row mb-3">
          {[1, 2, 3].map((item) => (
            <div key={item} className="col-12 col-md-4">
              <Skeleton variant="rect" height={100} className="mb-3" />
            </div>
          ))}
        </div>
        <div className="row mb-3">
          {[1, 2, 3, 4].map((item) => (
            <div key={item} className="col-12 col-md-6">
              <Skeleton variant="rect" height={300} className="mb-3" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const DashboardLoader = () => {
  return (
    <div className={classes.dashLoader}>
      <div style={{ display: 'flex', gap: 5 }}>
        <div style={{ width: 240 }}>
          <div style={{ padding: '20px 0' }}>
            <Skeleton variant="rect" height={50} />
          </div>
          <Divider />
          <div style={{ padding: '20px 0' }}>
            <Skeleton variant="rect" height={50} />
          </div>
          <div style={{ padding: '20px 0' }}>
            <Skeleton variant="text" />
          </div>
          <div style={{ padding: '20px 0' }}>
            <Skeleton variant="text" />
          </div>
          <div style={{ padding: '20px 0' }}>
            <Skeleton variant="text" />
          </div>
          <div style={{ padding: '20px 0' }}>
            <Skeleton variant="text" />
          </div>
          <div style={{ padding: '20px 0' }}>
            <Skeleton variant="text" />
          </div>
          <div style={{ padding: '20px 0' }}>
            <Skeleton variant="text" />
          </div>
        </div>
        <div style={{ flex: 1, padding: '20px 0' }}>
          <Skeleton variant="rect" height={50} />
        </div>
      </div>
    </div>
  );
};

export const SideLoader = (props) => {
  const classes = useStyles();
  return (
    // <Dialog open={true}>
    <div className={classes.side}>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </div>
      {/* <Lottie options={defaultOptions} height={100} width={100} /> */}
    </div>
    // </Dialog>
  );
};

export const SectionLoader = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          className={classes.top}
          classes={{
            circle: classes.circle,
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </div>
      {/* <Lottie options={defaultOptions} height={100} width={100} /> */}
    </div>
  );
};
export const Loader = (props) => {
  const classes = useStyles();
  return (
    <div>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.center}
        classes={{
          circle: classes.circle,
        }}
        size={25}
        thickness={5}
        {...props}
      />
    </div>
  );
};
