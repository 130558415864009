export default function validateImage(file) {
  if (!file) return false;
  try {
    const fileSize = file.size;
    const maxSize = 3 * 1024 * 1024;

    return fileSize > maxSize;
  } catch (error) {
    console.log('IMAGE_SIZE_VALIDATOR: ', error);
  }
}
