import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Paper, TextField } from '@material-ui/core';
import Menu from 'lib/components/Menu/Menu';
import BTNLoader from 'lib/components/Preloaders/Btn';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import authService from 'service/authService';
import httpService from 'service/httpService';
import authValidation from 'validations/auth/authValidation';
import { PATHNAMES } from 'lib/constants/pathnames';

const initialValues = {
  email: '',
};

const ForgotPassword = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);

  const { handleSubmit, errors, control } = useForm({
    mode: 'all',
    resolver: yupResolver(authValidation.FORGOT_PASSWORD),
    defaultValues: initialValues,
  });
  return (
    <div className="container-xxl">
      <Menu />
      <Paper
        className="p-3 py-5 border mx-auto my-4 rounded-4"
        style={{ maxWidth: 600 }}>
        <div className="fs-4 text-center">Forgot Password</div>
        <div className="mb-5 text-center px-md-4 fs-sm">
          Enter the email address you used to register with Jureb and we'll send
          you instructions to reset your password.
        </div>
        <form
          className="mx-auto"
          onSubmit={handleSubmit(save)}
          style={{ maxWidth: 350 }}>
          <div className="mb-4">
            <label>
              Email: <span className="text-danger">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              render={(props) => (
                <TextField
                  type="email"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  helperText={errors['email']?.message}
                  error={!!errors['email']}
                />
              )}
            />
          </div>

          <div className="mb-5">
            <Button
              fullWidth
              size="medium"
              variant="contained"
              color="primary"
              type="submit"
              style={{ textTransform: 'none' }}
              disabled={isLoading}
              startIcon={isLoading && <BTNLoader />}>
              Reset My Password
            </Button>
          </div>
          <div className="text-center my-2 mb-4">
            <Link className="text-primary" to={PATHNAMES.SIGN_IN}>
              Back to Login
            </Link>
          </div>
        </form>
      </Paper>
    </div>
  );

  async function save(formData) {
    try {
      setIsLoading(true);
      await authService.forgotPassword(formData);
      history.replace(PATHNAMES.FORGOT_PASSWORD_SUCCESS);
    } catch (ex) {
      setIsLoading(false);
      httpService.errorHandler(ex);
    }
  }
};

export default ForgotPassword;
