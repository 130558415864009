import { PATHNAMES } from 'lib/constants/pathnames';
import React from 'react';
import Dashboard from './dashboard';
import Invite from './invite';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import BrandLogo from 'lib/assets/icons/brand_logo.png';
import { DashboardOutlined } from '@material-ui/icons';
import InvoiceIcon from '../../components/Icons/invoice';
import Earnings from './earnings';
import BusinessInfo from './business-info';
import useUserData from 'hooks/useUserData';
import { useHistory } from 'react-router-dom';

const Referrer = () => {
  const { user } = useUserData();
  const history = useHistory();

  const pathname = window.location.pathname;
  const routes = [
    {
      title: 'Dashboard',
      path: PATHNAMES.REFERRAL__DASHBOARD,
      component: Dashboard,
      exact: true,
    },
    {
      title: 'Earnings',
      path: PATHNAMES.REFERRAL__EARNINGS,
      component: Earnings,
      exact: true,
    },
    {
      title: 'Business Details',
      path: PATHNAMES.REFERRAL__BUSINESS_INFO,
      component: BusinessInfo,
      exact: true,
    },
    {
      title: 'Invite Business',
      path: PATHNAMES.REFERRAL__NEW,
      component: Invite,
      exact: true,
    },
  ];

  const NAVIGATION_LINKS = [
    {
      id: 1,
      name: 'Dashboard',
      link: PATHNAMES.REFERRAL__DASHBOARD,
      Icon: DashboardOutlined,
    },
    {
      id: 2,
      name: 'Earnings',
      link: PATHNAMES.REFERRAL__EARNINGS,
      Icon: InvoiceIcon,
    },
  ];

  const LINK = `${location?.pathname}${location.search}`;

  React.useEffect(() => {
    if (!user) return;
    if (!user?.isVerified) {
      history.replace(
        `${PATHNAMES.ACCOUNT_VERIFICATION}?type=new&redirect=${LINK}`,
      );
    }
  }, [user]);
  return (
    <React.Fragment>
      <div className="d-lg-flex w-100" style={{ height: '100vh' }}>
        <div
          className="d-none d-lg-block bg-light border-end w-100"
          style={{ maxWidth: 240, overflowY: 'auto', height: '100vh' }}>
          <div className="d-flex justify-content-center py-4 border-bottom mb-4">
            <Link to="/">
              <img
                src={BrandLogo}
                alt="brand"
                style={{ width: '7rem', height: '3rem' }}
              />
            </Link>
          </div>
          <List component="nav" aria-label="sidebar navigation">
            {NAVIGATION_LINKS.map(({ Icon, link, name }) => (
              <ListItem
                button
                key={link}
                component={Link}
                to={link}
                className={pathname.includes(link) ? 'text-primary' : ''}>
                <ListItemIcon>
                  <Icon
                    className={pathname.includes(link) ? 'text-primary' : ''}
                  />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        </div>
        <div
          id="section-right"
          className="w-100"
          style={{ minHeight: '100vh', overflowY: 'auto' }}>
          <Switch>
            {routes.map(({ component: Component, ...route }, i) => (
              <Route
                key={i}
                {...route}
                render={(props) => <Component {...props} />}
              />
            ))}
            <Redirect
              from={PATHNAMES.REFERRAL}
              to={PATHNAMES.REFERRAL__DASHBOARD}
            />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Referrer;
