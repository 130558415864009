import React from 'react';

const StatContext = React.createContext({});

const StatContextProvider = ({ children }) => {
  const [stats, setStats] = React.useState(null);
  const [invoiceAnalytics, setInvoiceAnalytics] = React.useState([]);

  return (
    <StatContext.Provider
      value={{ stats, setStats, invoiceAnalytics, setInvoiceAnalytics }}>
      {children}
    </StatContext.Provider>
  );
};

export { StatContext, StatContextProvider };
