import fileDownload from 'js-file-download';
import http from './httpService';

export class BufferDownload {
  async download(path, fileName, callback) {
    const { data } = await http.get(path, {
      onDownloadProgress: (progressEvent) => {
        if (callback) {
          callback({ progressEvent, downloading: true });
        }
      },
    });
    const url = window.URL.createObjectURL(
      new Blob([new Uint8Array(data?.data?.data).buffer]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    return link.click();
  }
}

export class FileDownload {
  async download(path, fileName, callback) {
    http
      .axios({
        url: path,
        method: 'GET',
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          if (callback) {
            callback({ progressEvent, downloading: true });
          }
        },
      })
      .then((res) => {
        const href = URL.createObjectURL(res?.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(path);
      });
  }
}
